import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {GlobalService} from '../../../core/services/global.service';
import {SidebarService} from '../../../core/services/sidebar.service';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {UserProfile} from '../../../core/interfaces/user.interface';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'cto-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerRef: ElementRef;

  state = {
    loggedIn: false,
    navigation: true,
    shown: true,
    maintenance: environment.maintenance,
  };

  sidebarOpened = false;

  scrollThreshold = 0;
  lastScrollTop = 0;
  scrollingUp = false;
  scrolled = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private global: GlobalService,
    private sidebar: SidebarService,
    private store: StoreService,
  ) { }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.onScroll();
  }

  onScroll() {
    const scrollTop = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    this.scrolled = scrollTop > this.scrollThreshold;
    this.scrollingUp = scrollTop < this.lastScrollTop;
    this.lastScrollTop = scrollTop;
  }

  ngOnInit(): void {
    this.store.get<UserProfile>(StoreKey.UserProfile).subscribe(profile => {
      this.state.loggedIn = !!profile;
    });

    this.global.navigation.subscribe(shown => this.state.navigation = shown);
    this.global.header.subscribe(shown => this.state.shown = shown);

    this.sidebar.opened.subscribe(opened => {
      this.sidebarOpened = opened;
    })

    this.onScroll();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollThreshold = this.headerRef?.nativeElement.offsetHeight || 0;
      this.onScroll();
    }, 0);
  }

  toggleMenu() {
    document.body.classList.toggle('is-open-menu');
  }

  toggleSidebar() {
    this.sidebarOpened
      ? this.sidebar.close()
      : this.sidebar.open();
  }
}
