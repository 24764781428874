<div class="create-flow-modal__screen create-flow-modal__gallery-screen">
  <div class="create-flow-modal__gallery-header">
    <h2 class="create-flow-modal__gallery-title" style="text-align: center;">Media Library</h2>
  </div>
  <div *ngIf="gallery.length" class="create-flow-modal__gallery-preview">
    <ng-container *ngIf="selectedIndex > -1">
      <div [ngSwitch]="gallery[selectedIndex].type">
        <div *ngSwitchCase="'image'">
          <img  [src]="gallery[selectedIndex].url" alt="" class="create-flow-modal__gallery-preview-image" [ngStyle]="imageStyle(gallery[selectedIndex])">
          <button (click)="cropImage(selectedIndex)" type="button" class="create-flow-modal__gallery-crop-button">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6667 14.0373H14.3333V4.37061C14.3333 4.18661 14.184 4.03727 14 4.03727H4.33333V1.70394C4.33333 1.51994 4.184 1.37061 4 1.37061C3.816 1.37061 3.66667 1.51994 3.66667 1.70394V4.03727H1.33333C1.14933 4.03727 1 4.18661 1 4.37061C1 4.55461 1.14933 4.70394 1.33333 4.70394H3.66667V14.3706C3.66667 14.5546 3.816 14.7039 4 14.7039H13.6667V17.0373C13.6667 17.2213 13.816 17.3706 14 17.3706C14.184 17.3706 14.3333 17.2213 14.3333 17.0373V14.7039H16.6667C16.8507 14.7039 17 14.5546 17 14.3706C17 14.1866 16.8507 14.0373 16.6667 14.0373ZM4.33333 14.0373V4.70394H13.6667V14.0373H4.33333Z" fill="white" stroke="black"/>
            </svg>
          </button>
          <button (click)="deleteItem(selectedIndex)" type="button" class="create-flow-modal__gallery-delete-button">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_128_751)">
                <path d="M7.67228 2.87048H1.42228C1.19228 2.87048 1.00562 3.05715 1.00562 3.28715V4.95382C1.00562 5.18382 1.19228 5.37048 1.42228 5.37048H1.89312L3.95395 20.0113C3.98311 20.2171 4.15895 20.3705 4.36645 20.3705H15.9781C16.1856 20.3705 16.3614 20.2171 16.3906 20.0113L18.4514 5.37048H18.9223C19.1523 5.37048 19.3389 5.18382 19.3389 4.95382V3.28715C19.3389 3.05715 19.1523 2.87048 18.9223 2.87048H12.6723C12.6723 1.49215 11.5506 0.370483 10.1723 0.370483C8.79395 0.370483 7.67228 1.49215 7.67228 2.87048ZM15.6156 19.5371H4.72895L2.73478 5.37048H17.6089L15.6156 19.5371ZM18.5056 3.70382V4.53715H1.83895V3.70382H18.5056ZM10.1723 1.20382C11.0914 1.20382 11.8389 1.95132 11.8389 2.87048H8.50561C8.50561 1.95132 9.25311 1.20382 10.1723 1.20382Z" fill="black"/>
                <path d="M12.2208 17.8688C12.2333 17.8697 12.245 17.8705 12.2558 17.8705C12.47 17.8705 12.6525 17.7063 12.6708 17.4888L13.5041 7.48884C13.5233 7.25967 13.3533 7.05717 13.1233 7.03884C12.8891 7.02384 12.6925 7.19051 12.6733 7.41884L11.84 17.4188C11.8216 17.648 11.9916 17.8505 12.2208 17.8688Z" fill="black"/>
                <path d="M8.08904 17.8705C8.10071 17.8705 8.11238 17.8696 8.12404 17.8688C8.35321 17.8505 8.52321 17.648 8.50488 17.4188L7.67154 7.41882C7.65238 7.19048 7.45404 7.02298 7.22154 7.03882C6.99238 7.05715 6.82238 7.25965 6.84071 7.48882L7.67404 17.4888C7.69154 17.7063 7.87404 17.8705 8.08904 17.8705Z" fill="black"/>
              </g>
              <defs>
                <clipPath id="clip0_128_751">
                  <rect width="20" height="20" fill="white" transform="translate(0.172363 0.370483)"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div *ngSwitchCase="'video'">
          <div class="create-flow-modal__gallery-preview-video">
            <img *ngIf="gallery[selectedIndex].poster?.url || gallery[selectedIndex].poster_image_url"  [src]="gallery[selectedIndex].poster?.url || gallery[selectedIndex].poster_image_url" alt="" class="create-flow-modal__gallery-preview-image">
            <svg width="49" height="58" viewBox="0 0 49 58" fill="none" xmlns="http://www.w3.org/2000/svg" style="z-index: 1;">
              <g opacity="0.9" filter="url(#filter0_d_210:40)">
                <path d="M42.7046 21.6264C45.1723 23.1986 45.1723 26.8014 42.7046 28.3736L10.6492 48.7953C7.9864 50.4917 4.5 48.579 4.5 45.4217L4.5 4.57825C4.5 1.42098 7.98641 -0.49171 10.6492 1.2047L42.7046 21.6264Z" fill="white"/>
              </g>
              <defs>
                <filter id="filter0_d_210:40" x="0.5" y="0.571777" width="48.0554" height="56.8564" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_210:40"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_210:40" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
          <button (click)="addPosterImage(selectedIndex)" type="button" class="create-flow-modal__cover-button">
            Add Video Cover Art
          </button>
          <button (click)="deleteItem(selectedIndex)" type="button" class="create-flow-modal__gallery-delete-button">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_128_751)">
                <path d="M7.67228 2.87048H1.42228C1.19228 2.87048 1.00562 3.05715 1.00562 3.28715V4.95382C1.00562 5.18382 1.19228 5.37048 1.42228 5.37048H1.89312L3.95395 20.0113C3.98311 20.2171 4.15895 20.3705 4.36645 20.3705H15.9781C16.1856 20.3705 16.3614 20.2171 16.3906 20.0113L18.4514 5.37048H18.9223C19.1523 5.37048 19.3389 5.18382 19.3389 4.95382V3.28715C19.3389 3.05715 19.1523 2.87048 18.9223 2.87048H12.6723C12.6723 1.49215 11.5506 0.370483 10.1723 0.370483C8.79395 0.370483 7.67228 1.49215 7.67228 2.87048ZM15.6156 19.5371H4.72895L2.73478 5.37048H17.6089L15.6156 19.5371ZM18.5056 3.70382V4.53715H1.83895V3.70382H18.5056ZM10.1723 1.20382C11.0914 1.20382 11.8389 1.95132 11.8389 2.87048H8.50561C8.50561 1.95132 9.25311 1.20382 10.1723 1.20382Z" fill="#9C9C9C"/>
                <path d="M12.2208 17.8688C12.2333 17.8697 12.245 17.8705 12.2558 17.8705C12.47 17.8705 12.6525 17.7063 12.6708 17.4888L13.5041 7.48884C13.5233 7.25967 13.3533 7.05717 13.1233 7.03884C12.8891 7.02384 12.6925 7.19051 12.6733 7.41884L11.84 17.4188C11.8216 17.648 11.9916 17.8505 12.2208 17.8688Z" fill="#9C9C9C"/>
                <path d="M8.08904 17.8705C8.10071 17.8705 8.11238 17.8696 8.12404 17.8688C8.35321 17.8505 8.52321 17.648 8.50488 17.4188L7.67154 7.41882C7.65238 7.19048 7.45404 7.02298 7.22154 7.03882C6.99238 7.05715 6.82238 7.25965 6.84071 7.48882L7.67404 17.4888C7.69154 17.7063 7.87404 17.8705 8.08904 17.8705Z" fill="#9C9C9C"/>
              </g>
              <defs>
                <clipPath id="clip0_128_751">
                  <rect width="20" height="20" fill="white" transform="translate(0.172363 0.370483)"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!gallery.length" ctoFilePicker [options]="filePickerOptions" class="create-flow-modal__gallery-empty" (change)="onFileChange($event)" (dragenter)="toggleDragOver($event)" (dragleave)="toggleDragOver($event)">
    <div *ngIf="dragOver" class="create-flow-modal__gallery-drop-zone"></div>
    <svg class="create-flow-modal__gallery-icons" width="114" height="24" viewBox="0 0 114 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_398_930)">
        <path d="M67.667 1.5H49.667C48.017 1.5 46.667 2.85 46.667 4.5V16.5V19.5V21C46.667 21.9 47.267 22.5 48.167 22.5H49.667H61.667H67.667C69.317 22.5 70.667 21.15 70.667 19.5V4.5C70.667 2.85 69.317 1.5 67.667 1.5ZM49.667 19.5V17.1L52.667 14.1L58.067 19.5H49.667ZM67.667 19.5H62.267L53.717 10.95C53.117 10.35 52.217 10.35 51.617 10.95L49.667 12.9V4.5H67.667V19.5Z" fill="#393B48"/>
        <path d="M62.417 13.5C64.4881 13.5 66.167 11.8211 66.167 9.75C66.167 7.67893 64.4881 6 62.417 6C60.3459 6 58.667 7.67893 58.667 9.75C58.667 11.8211 60.3459 13.5 62.417 13.5Z" fill="#393B48"/>
      </g>
      <path d="M17.25 10.6501L9.75 6.1501C9.3 6.0001 8.7 6.0001 8.25 6.1501C7.8 6.4501 7.5 6.9001 7.5 7.5001V16.5001C7.5 17.1001 7.8 17.5501 8.25 17.8501C8.55 18.0001 8.7 18.0001 9 18.0001C9.3 18.0001 9.6 18.0001 9.75 17.8501L17.25 13.3501C17.7 13.0501 18 12.6001 18 12.0001C18 11.4001 17.7 10.9501 17.25 10.6501ZM10.5 13.8001V10.2001L13.65 12.0001L10.5 13.8001Z" fill="#393B48"/>
      <path d="M18 0H6C2.7 0 0 2.7 0 6V18C0 21.3 2.7 24 6 24H18C21.3 24 24 21.3 24 18V6C24 2.7 21.3 0 18 0ZM21 18C21 19.65 19.65 21 18 21H6C4.35 21 3 19.65 3 18V6C3 4.35 4.35 3 6 3H18C19.65 3 21 4.35 21 6V18Z" fill="#393B48"/>
      <path d="M112.933 3.06675C112.667 2.80008 112.4 2.66675 112 2.66675H100C99.2 2.66675 98.6667 3.20008 98.6667 4.00008V15.0667C98 14.8001 97.3333 14.6667 96.6667 14.6667C94.1333 14.6667 92 16.8001 92 19.3334C92 21.8667 94.1333 24.0001 96.6667 24.0001C99.2 24.0001 101.333 21.8667 101.333 19.3334C101.333 19.2001 101.333 19.2001 101.333 19.0667V10.6667H110.667V13.7334C110 13.4667 109.333 13.3334 108.667 13.3334C106.133 13.3334 104 15.4667 104 18.0001C104 20.5334 106.133 22.6667 108.667 22.6667C111.2 22.6667 113.333 20.5334 113.333 18.0001V4.00008C113.333 3.60008 113.2 3.33341 112.933 3.06675ZM96.6667 21.3334C95.6 21.3334 94.6667 20.4001 94.6667 19.3334C94.6667 18.2667 95.6 17.3334 96.6667 17.3334C97.7333 17.3334 98.6667 18.2667 98.6667 19.3334C98.6667 20.4001 97.7333 21.3334 96.6667 21.3334ZM101.333 8.00008V5.33341H110.667V8.00008H101.333ZM108.667 20.0001C107.6 20.0001 106.667 19.0667 106.667 18.0001C106.667 16.9334 107.6 16.0001 108.667 16.0001C109.733 16.0001 110.667 16.9334 110.667 18.0001C110.667 19.0667 109.733 20.0001 108.667 20.0001Z" fill="#393B48"/>
      <defs>
        <clipPath id="clip0_398_930">
          <rect width="24" height="24" fill="white" transform="translate(46.667)"/>
        </clipPath>
      </defs>
    </svg>
    <h3 class="create-flow-modal__gallery-empty-title">Share your project, causes or passion</h3>
    <p class="create-flow-modal__gallery-empty-description">
      Drag and drop or choose up to 9 files.
    </p>
    <button type="button" class="new-form-button create-flow-modal__gallery-button">+ Add Media</button>
  </div>
  <div *ngIf="gallery.length" class="create-flow-modal__library" [sortablejs]="gallery" [sortablejsOptions]="sortableOptions">
    <div class="create-flow-modal__library-draggable" *ngFor="let item of gallery; let i = index">
      <div class="create-flow-modal__library-item" (click)="select(i)">
        <div [ngSwitch]="item.type" class="create-flow-modal__library-item-content create-flow-modal__library-item-content--image" [class.is-selected]="isSelected(i)">
          <img *ngSwitchCase="'image'" [src]="item.url" alt="" class="create-flow-modal__library-item-image">
          <div *ngSwitchCase="'video'" class="create-flow-modal__library-item-video">
            <img *ngIf="item.poster?.url || item.poster_image_url" [src]="item.poster?.url || item.poster_image_url" alt="" class="create-flow-modal__library-item-poster">
            <svg *ngIf="item.poster?.url || item.poster_image_url" width="30" viewBox="0 0 49 58" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: relative; z-index: 7;">
              <g opacity="0.9" filter="url(#filter0_d_210:40)">
                <path d="M42.7046 21.6264C45.1723 23.1986 45.1723 26.8014 42.7046 28.3736L10.6492 48.7953C7.9864 50.4917 4.5 48.579 4.5 45.4217L4.5 4.57825C4.5 1.42098 7.98641 -0.49171 10.6492 1.2047L42.7046 21.6264Z" fill="white"/>
              </g>
              <defs>
                <filter id="filter0_d_210:40" x="0.5" y="0.571777" width="48.0554" height="56.8564" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_210:40"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_210:40" result="shape"/>
                </filter>
              </defs>
            </svg>
            <svg *ngIf="!item.poster?.url && !item.poster_image_url" width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: relative; z-index: 7;">
              <path d="M19.0868 1.0098V6.57501H24.652" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M24.652 31.8969C24.652 32.5043 24.1595 33.0098 23.5508 33.0098H2.09523C1.48779 33.0098 1 32.5043 1 31.8969V2.13063C1 1.52347 1.48779 1.0098 2.09523 1.0098H19.0868C19.3757 1.0098 19.5099 1.13418 19.7153 1.33661L24.3238 5.88437C24.5329 6.0907 24.652 6.28089 24.652 6.57501V31.8969Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.5555 13.8808L14.9364 16.3193V14.2531C14.9364 13.8282 14.6257 13.4842 14.2438 13.4842H5.92034C5.53717 13.4842 5.22705 13.8282 5.22705 14.2531V22.5302C5.22705 22.9551 5.53731 23.2995 5.92034 23.2995H14.2438C14.6259 23.2995 14.9364 22.9551 14.9364 22.5302V20.4648L19.5555 22.9032C19.956 23.1005 20.4123 22.7754 20.4123 22.2845V14.4995C20.4123 14.0089 19.958 13.6831 19.5555 13.8808Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="gallery.length < galleryLimit">
      <div ctoFilePicker [options]="filePickerOptions" class="create-flow-modal__library-item not-draggable" (change)="onFileChange($event)">
        <div class="create-flow-modal__library-item-content">
          <div class="create-flow-modal__library-item-empty">
            +
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
