<div class="create-flow-modal" *ngIf="opened">
  <div class="create-flow-modal__body">
    <div class="create-flow-modal__main">
      <!-- Step 3: Gallery -->
      <div class="create-flow-modal__content">
        <cto-edit-gallery-screen (success)="gallerySuccess()"></cto-edit-gallery-screen>
        <div class="create-flow-modal__actions">
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button" (click)="cancel()">Cancel</button>
          </div>
          <div class="create-flow-modal__action-wrapper"></div>
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button new-form-button--primary" (click)="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="create-flow-modal__backdrop"></div>
</div>
