<div class="custom-cropper-modal" [class.is-opened]="opened">
  <div class="custom-cropper-modal__backdrop"></div>
  <div class="custom-cropper-modal__body">
    <div class="custom-cropper-modal__main">
      <div class="custom-cropper-modal__header">
        <h3 class="custom-cropper-modal__title">Image Cropping</h3>
      </div>
      <div class="custom-cropper">
        <div class="custom-cropper__image-wrapper">
          <img
            class="custom-cropper__image"
            #image
            src=""
            alt=""
            [ngStyle]="{ top: styleTop, left: styleLeft, width: styleWidth, height: styleHeight }"
            (dragstart)="onDragStart($event)"
            (mousedown)="onMouseDown($event)"
            (touchstart)="onTouchStart($event)"
            (mousemove)="onMouseMove($event)"
            (touchmove)="onTouchMove($event)"
            (mouseup)="onMouseUp($event)"
            (touchend)="onTouchEnd($event)">
        </div>
        <div class="custom-cropper__grid">
          <div class="custom-cropper__grid-horizontal">
            <div class="custom-cropper__horizontal-grid-line"></div>
            <div class="custom-cropper__horizontal-grid-line"></div>
          </div>
          <div class="custom-cropper__grid-vertical">
            <div class="custom-cropper__vertical-grid-line"></div>
            <div class="custom-cropper__vertical-grid-line"></div>
          </div>
        </div>
        <div class="custom-cropper__actions">
          <button (click)="zoomOut()" type="button" class="custom-cropper__action">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,13H5V11H19V13Z" />
            </svg>
          </button>
          <button (click)="zoomIn()" type="button" class="custom-cropper__action">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="custom-cropper-modal__actions">
        <div class="custom-cropper-modal__action-wrapper">
          <button (click)="cancel()" type="button" class="new-form-button-inverse">Cancel</button>
        </div>
        <div class="custom-cropper-modal__action-wrapper">
          <button (click)="save()" type="button" class="new-form-button-inverse new-form-button-inverse--primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
