<div (clickOutside)="clickOutside()" *ngIf="events?.length" class="uploader">
  <div class="uploader__header">
    <h4 class="uploader__title">Updating media...</h4>
    <button *ngIf="(count$ | async) < 1" type="button" class="uploader__close" (click)="close()">
      <svg width="16" height="16" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.986604 19.5548C1.06679 19.6342 1.17152 19.6743 1.27624 19.6743C1.38097 19.6743 1.4857 19.6342 1.56588 19.5548L10.2763 10.8434L18.9868 19.554C19.067 19.6342 19.1717 19.6743 19.2765 19.6743C19.3812 19.6743 19.4859 19.6342 19.5661 19.5548C19.7256 19.3953 19.7256 19.1359 19.5661 18.9764L10.8548 10.265L19.5661 1.55442C19.7256 1.39487 19.7256 1.1355 19.5661 0.975949C19.4065 0.8164 19.1472 0.8164 18.9876 0.975949L10.2763 9.68651L1.56588 0.975131C1.40634 0.815582 1.14697 0.815582 0.987422 0.975131C0.827875 1.13468 0.827875 1.39405 0.987422 1.5536L9.69789 10.265L0.986604 18.9755C0.827057 19.1359 0.827057 19.3945 0.986604 19.5548Z" fill="#ffffff" stroke="#ffffff"/>
      </svg>
    </button>
  </div>
  <div class="uploader__items">
    <div *ngFor="let event of events" class="uploader__item">
      <div class="uploader__icon-wrapper">
        <svg *ngIf="event.type === 'image'" width="24" xmlns="http://www.w3.org/2000/svg" class="uploader__icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <svg *ngIf="event.type === 'video'" width="24" xmlns="http://www.w3.org/2000/svg" class="uploader__icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
        <svg *ngIf="event.type === 'delete'" width="24" xmlns="http://www.w3.org/2000/svg" class="uploader__icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </div>
      <div class="uploader__message-wrapper">
        <div class="uploader__message">
          {{ (event.subject | async).message }}
        </div>
        <div class="uploader__progress-wrapper">
          <div class="uploader__progress">
            <div class="uploader__progress-fill" [style.width]="(event.subject | async).progress + '%'"></div>
          </div>
          <span class="uploader__percentage">
            {{ (event.subject | async).progress }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
