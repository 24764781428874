import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CardType} from '../enums/card-type';
import {UploadUrlOptions} from '../interfaces/upload-url-options';
import {ImageUploadData, ImageUploadResponse} from '../interfaces/image.interface';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {VideoUploadData} from '../interfaces/video.interface';
// import {ImageUploadData} from '../interfaces/image-upload-data';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
  ) { }

  readAsDataURL(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e.target.error);

      reader.readAsDataURL(file);
    });
  }

  getUploadUrls({ cardId, cardType, imageType, multiple = 1 }: UploadUrlOptions) {
    return this.http.post(`/api/v1/cards/${cardType}/${cardId}/images`, { imageType, multiple });
  }

  getUploadUrl(): Observable<ImageUploadData> {
    return this.http.post<ImageUploadData>(`${environment.url.tapper}/v1/images/upload`, {});
  }

  upload(file: File, url: string, { method = 'post', useFormData = false, progress = false } = {}): Observable<any> {
    if(useFormData) {
      const data = new FormData();

      data.append('file', file);

      return this.http[method || 'post']<any>(url, data, progress ? {
        reportProgress: true,
        observe: 'events',
      } : {});
    }

    return this.http[method || 'post']<any>(url, file, progress ? {
      reportProgress: true,
      observe: 'events',
    } : {});
  }

  getImageUploadUrl() {
    return this.http.post<any>(`${environment.url.tapper}/v1/images/upload`, {});
  }

  getVideoUploadUrl(): Observable<VideoUploadData> {
    return this.http.post<any>(`${environment.url.tapper}/v1/videos/upload`, {
      test_mode: false,
    });
  }

  uploadImage(file: File): Promise<any> {
    return new Promise(resolve => {
      if(!file) {
        resolve(null);
        return;
      }

      this.getImageUploadUrl().pipe(first()).subscribe(({ image_id, upload_url }: ImageUploadData) => {
        this.upload(file, upload_url, { useFormData: true }).pipe(first()).subscribe(uploaded => {
          resolve({ id: image_id, url: uploaded?.result?.variants?.[0] || '' });
        }, () => resolve(null));
      }, () => resolve(null));
    });
  }

  observeUploadImage(file: File): Promise<any> {
    return new Promise(resolve => {
      if(!file) {
        resolve(null);
        return;
      }

      this.getImageUploadUrl().pipe(first()).subscribe(({ image_id, upload_url }: ImageUploadData) => {
        resolve({ id: image_id, progress: this.upload(file, upload_url, { useFormData: true, progress: true }) });
      }, () => resolve(null));
    });
  }

  observeUploadVideo(file: File): Promise<any> {
    return new Promise(resolve => {
      if(!file) {
        resolve(null);
        return;
      }

      this.getVideoUploadUrl().pipe(first()).subscribe(({ video_id, upload_url }: VideoUploadData) => {
        resolve({ id: video_id, progress: this.upload(file, upload_url, { method: 'put', useFormData: false, progress: true }) });
      }, () => resolve(null));
    });
  }

  uploadVideo(file: File): Promise<any> {
    return new Promise(resolve => {
      if(!file) {
        resolve(null);
        return;
      }

      this.getVideoUploadUrl().pipe(first()).subscribe(({ video_id, upload_url }: VideoUploadData) => {
        this.upload(file, upload_url, { method: 'put', useFormData: false }).pipe(first()).subscribe(uploaded => {
          resolve({ id: video_id });
        }, () => resolve(null));
      }, () => resolve(null));
    });
  }
}
