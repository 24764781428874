import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  checkout = null;

  constructor() {
    this.load();
  }

  private load() {
    if (this.checkout) {
      return;
    }

    this.checkout = new (window as any).ContributeTo.GuestCheckout({
      env: environment.url.tapper.includes('sbx')
        ? 'sbx'
        : environment.url.tapper.includes('stg')
        ? 'stg'
        : 'prod',
    });

    return this.checkout?.load();
  }

  render(id, options) {
    this.checkout?.render(id, options);
  }

  destroy() {
    // this.preflight();

    this.checkout?.destroy();
  }
}
