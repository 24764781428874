import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {homepage} from '../../../core/data/homepage.data';
import SwiperCore, {SwiperOptions, Navigation, Autoplay, Pagination} from 'swiper';
import {MailchimpResponse, MailchimpService} from '../../../core/services/mailchimp.service';
import {FormBuilder, Validators} from '@angular/forms';
import videojs from '@mux/videojs-kit';
import {first} from 'rxjs/operators';

SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
  selector: 'cto-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {
  @ViewChild('videoIconsPlayer') videoIconsPlayer: ElementRef;

  words = ['illustrator', 'photographer', 'artist'];
  word = '';

  data: any = homepage;

  videoIconsPlayerInstance: any;

  cardSliderConfig: SwiperOptions = {
    slidesPerView: 1.5,
    spaceBetween: 30,
    centeredSlides: true,
    loop: false,
    setWrapperSize: true,
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
      },
      500: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      756: {
        slidesPerView: 3,
        spaceBetween: 34,
        centeredSlides: false,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 44,
        centeredSlides: false,
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 74,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.js-slider-button-next',
      prevEl: '.js-slider-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  supporterSliderConfig: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      756: {
        spaceBetween: 50,
      },
    },
    navigation: {
      nextEl: '.js-supporters-button-next',
      prevEl: '.js-supporters-button-prev',
    },
  };

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  subscription = {
    success: '',
    error: '',
  };

  constructor(
    public auth: AuthService,
    private mailchimp: MailchimpService,
    private fb: FormBuilder,
  ) { }

  async ngOnInit(): Promise<any> {
    await this.animate();
  }

  ngAfterViewInit() {
    this.videoIconsPlayerInstance = videojs(this.videoIconsPlayer.nativeElement);
    this.videoIconsPlayerInstance.src({
      src: 'IYtSpkv9SDdqWrPS5We4XbBW866i4uVm47puyNlC83s',
      type: 'video/mux',
    });
  }

  async animate(): Promise<any> {
    let index = 0;
    const delay = 1500;

    const write = async () => {
      await this.writeWord(this.words[index]);

      index = (index + 1) % this.words.length;

      setTimeout(write, delay);
    };

    await write();
  }

  writeWord(word: string): Promise<boolean> {
    return new Promise(resolve => {
      let index = 0;
      const speed = 80;
      this.word = '';

      const write = () => {
        if (index >= word.length) {
          resolve(true);
          return;
        }

        this.word += word.charAt(index);
        index++;
        setTimeout(write, speed);
      };

      write();
    });
  }

  subscribe() {
    this.subscription = {
      success: '',
      error: '',
    };

    if(this.form.invalid) {
      this.subscription.error = 'Please enter a valid email.';
      return;
    }

    this.mailchimp.subscribe({ email: this.form.value.email }).pipe(first()).subscribe(
      (response: MailchimpResponse) => {
        if (response.result === 'success') {
          this.subscription.success = response.msg;
        } else {
          this.subscription.error = response.msg;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

