import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorAlertService} from '../services/error-alert.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private service: ErrorAlertService,
  ) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }

    this.zone.run(() => this.service.open());

    console.error('Global error handler caught the following error:', error);
  }
}
