import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaGalleryService {
  state = {
    opened: new BehaviorSubject<number>(-1),
  }

  constructor() { }

  get opened() {
    return this.state.opened.asObservable();
  }

  open(index: number) {
    this.state.opened.next(index);
  }

  close() {
    this.state.opened.next(-1);
  }
}
