<header
  #header
  *ngIf="state.shown && state.navigation"
  class="wp-cto-header js-header"
  [class.is-page-scrolled]="scrolled"
  [class.wp-cto-header--scroll-up]="scrollingUp"
>
  <div class="wp-cto-header__wrapper wp-cto-header__wrapper--desktop">
    <a
      class="wp-cto-header__logo"
      href=" https://contribute.to"
      aria-label="Link to homepage"
    >
      <svg
        width="327"
        height="45"
        viewBox="0 0 327 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M178.883 34.4415C178.865 34.8276 178.794 35.2313 178.568 35.5597C178.39 35.8259 178.124 36.0345 177.827 36.1543C177.56 36.2608 177.263 36.314 176.966 36.3007C176.668 36.3096 176.371 36.2608 176.105 36.1543C175.808 36.0345 175.541 35.8259 175.355 35.5597C175.138 35.2313 175.067 34.8276 175.049 34.4415C175.04 34.2241 175.049 16.6486 175.049 16.409V15.4594C175.049 14.8959 175.275 14.4522 175.612 14.1238C175.95 13.7866 176.402 13.5603 176.966 13.5603C177.529 13.5603 177.973 13.7866 178.319 14.1238C178.656 14.4522 178.883 14.8959 178.883 15.4594V16.409C178.883 16.6486 178.891 34.2241 178.883 34.4415Z"
          fill="white"
        ></path>
        <path
          d="M282.228 31.2867C280.759 31.2867 279.521 32.5114 279.521 33.9623C279.521 35.4133 280.764 36.6379 282.228 36.6379C283.692 36.6379 284.935 35.4133 284.935 33.9623C284.935 32.5114 283.692 31.2867 282.228 31.2867Z"
          fill="white"
        ></path>
        <path
          d="M176.963 11.009C178.272 11.009 179.333 9.9618 179.333 8.67059C179.333 7.37938 178.272 6.33221 176.963 6.33221C175.654 6.33221 174.594 7.37938 174.594 8.67059C174.594 9.9618 175.654 11.009 176.963 11.009Z"
          fill="white"
        ></path>
        <path
          d="M72.7157 17.0257C73.9581 16.2448 75.4224 15.912 77.002 15.912C77.6365 15.912 78.271 15.9653 78.8878 16.0984C79.4957 16.2271 80.0858 16.4267 80.6449 16.6885C80.9067 16.8128 81.1596 16.9414 81.3948 17.1056C81.6433 17.2742 81.874 17.465 82.1003 17.6603C82.3488 17.8732 82.5973 18.0951 82.8946 18.2282C83.1963 18.3613 83.5379 18.3968 83.8618 18.3391C84.3411 18.2548 84.7892 17.9842 85.0643 17.576C85.1042 17.5183 85.1397 17.4561 85.1708 17.394C85.3084 17.1145 85.366 16.7995 85.3572 16.4889C85.3527 16.2626 85.3217 16.0318 85.2241 15.8277C85.0688 15.4905 84.8203 15.2997 84.6383 15.1488C84.3854 14.9358 84.1236 14.7362 83.8574 14.5409C83.6089 14.359 83.356 14.1815 83.0898 14.0262C81.2839 13.0234 79.1407 12.4644 76.8822 12.4644C74.6237 12.4644 72.5959 13.0234 70.6746 14.0262C68.8687 15.029 67.4045 16.4755 66.273 18.3702C65.2569 20.1539 64.6934 22.2705 64.6934 24.387C64.6934 26.6144 65.2569 28.62 66.273 30.4037C67.2891 32.1875 68.7533 33.634 70.6746 34.6368C72.4806 35.6396 74.6237 36.1987 76.8822 36.1987C78.0581 36.1987 79.2295 36.0434 80.3609 35.7283C81.5235 35.4044 82.6194 34.9563 83.6311 34.2863C83.9284 34.091 84.2168 33.8869 84.5008 33.6739C84.7936 33.4609 85.0865 33.2347 85.2684 32.9196C85.4415 32.6179 85.4992 32.254 85.4503 31.9124C85.4015 31.5485 85.2551 31.1625 85.0111 30.8874C84.6428 30.4703 84.0615 30.2618 83.5113 30.3017C83.0454 30.3328 82.5973 30.5324 82.2378 30.8253C81.9139 31.0915 81.5323 31.3178 81.1641 31.5175C79.9217 32.1875 78.4574 32.6312 76.8778 32.6312C75.2981 32.6312 73.9448 32.2984 72.5915 31.5175C71.3491 30.7365 70.333 29.7337 69.5432 28.5091C68.8643 27.1735 68.4161 25.7226 68.4161 24.1651C68.4161 22.6077 68.7533 21.0458 69.5432 19.8211C70.333 18.8184 71.3491 17.7046 72.7024 17.0346"
          fill="white"
        ></path>
        <path
          d="M108.115 24.5999C108.115 26.1618 107.778 27.7192 106.988 28.9439C106.314 30.1686 105.298 31.2823 103.94 31.9523C102.697 32.6223 101.233 33.066 99.6536 33.066C98.0739 33.066 96.7206 32.7332 95.3673 31.9523C94.1249 31.1713 93.1088 30.1686 92.319 28.9439C91.6401 27.6083 91.1919 26.1574 91.1919 24.5999C91.1919 22.3769 91.9329 20.2515 93.5125 18.6675C94.7549 17.4162 96.3834 16.5376 98.1227 16.2315C100.199 15.8632 102.405 16.3113 104.193 17.4295C105.329 18.135 106.274 19.1378 106.993 20.2604C107.782 21.485 108.12 23.0469 108.12 24.6044L108.115 24.5999ZM105.976 14.2392C104.171 13.2364 102.027 12.6773 99.7689 12.6773C97.5104 12.6773 95.4826 13.2364 93.5614 14.2392C91.7554 15.242 90.2912 16.6885 89.1597 18.5832C88.1436 20.3669 87.5801 22.4834 87.5801 24.5999C87.5801 26.8274 88.1436 28.833 89.1597 30.6167C90.1758 32.4004 91.6401 33.8469 93.5614 34.8497C95.3673 35.8525 97.5104 36.4116 99.7689 36.4116C102.027 36.4116 104.055 35.8525 105.976 34.8497C107.782 33.8469 109.251 32.4004 110.378 30.6167C111.394 28.833 111.958 26.7164 111.958 24.5999C111.958 22.3725 111.394 20.3669 110.378 18.5832C109.251 16.6885 107.782 15.3529 105.976 14.2392Z"
          fill="white"
        ></path>
        <path
          d="M323.167 25.0481C323.167 26.61 322.83 28.1674 322.04 29.3921C321.361 30.6167 320.349 31.7305 318.992 32.4005C317.749 33.0705 316.285 33.5142 314.705 33.5142C313.126 33.5142 311.772 33.1814 310.419 32.4005C309.177 31.6195 308.161 30.6167 307.371 29.3921C306.692 28.0565 306.244 26.6055 306.244 25.0481C306.244 23.4907 306.581 21.9288 307.371 20.7041C308.045 19.3686 309.061 18.3658 310.419 17.6958C311.662 16.9148 313.126 16.582 314.705 16.582C316.285 16.582 317.638 16.9148 318.992 17.6958C320.234 18.4767 321.25 19.4795 322.04 20.7041C322.83 22.0397 323.167 23.4907 323.167 25.0481ZM325.421 19.0313C324.405 17.2476 322.941 15.8011 321.019 14.6874C319.214 13.6846 317.07 13.1255 314.812 13.1255C312.553 13.1255 310.526 13.6846 308.604 14.6874C306.798 15.6902 305.33 17.1367 304.203 19.0313C303.187 20.8151 302.623 22.9316 302.623 25.0481C302.623 27.1646 303.187 29.2811 304.203 31.0649C305.219 32.8486 306.687 34.2951 308.604 35.2979C310.41 36.3007 312.553 36.8598 314.812 36.8598C317.07 36.8598 319.098 36.3007 321.019 35.2979C322.825 34.2951 324.29 32.8486 325.421 31.0649C326.437 29.2811 327.001 27.1646 327.001 25.0481C327.001 22.9316 326.437 20.8151 325.421 19.0313Z"
          fill="white"
        ></path>
        <path
          d="M263.403 16.6885C264.53 16.6885 265.661 16.9104 266.788 17.3585C267.805 17.8022 268.705 18.4723 269.495 19.3641C270.174 20.1451 270.622 21.1479 270.738 22.2616H256.295C256.747 20.5888 257.648 19.2532 258.89 18.2504C260.244 17.2476 261.712 16.6885 263.403 16.6885ZM274.132 25.2212C274.199 25.1235 274.261 25.0215 274.309 24.915C274.811 23.859 274.607 22.4657 274.341 21.3697C274.061 20.2161 273.52 19.2354 272.881 18.2504C271.865 16.6885 270.4 15.3529 268.705 14.572C267.015 13.7911 265.209 13.3474 263.288 13.3474C261.366 13.3474 259.449 13.7911 257.759 14.7939C256.068 15.7967 254.6 17.1322 253.584 19.0269C252.567 20.8106 252.004 23.0381 252.004 25.3765C252.004 27.7148 252.567 29.7204 253.584 31.5042C254.6 33.2879 256.179 34.6235 257.985 35.6263C259.902 36.6291 262.045 37.0728 264.419 37.0728C266.11 37.0728 267.805 36.74 269.384 35.9591C270.431 35.4399 271.434 34.8232 272.317 34.0555C273.049 33.4166 274.048 32.6401 273.697 31.5397C273.502 30.9229 272.912 30.457 272.268 30.3727C271.372 30.2529 270.804 30.7898 270.227 31.3755C269.593 32.0189 268.856 32.5913 268.026 32.9551C266.899 33.5142 265.657 33.7361 264.304 33.7361C262.498 33.7361 260.918 33.4033 259.68 32.6223C258.327 31.8414 257.311 30.8386 256.632 29.6139C255.957 28.5002 255.616 27.1646 255.616 25.8246H272.428C272.885 25.8069 273.395 25.8157 273.795 25.5539C273.928 25.4652 274.039 25.3498 274.132 25.2167"
          fill="white"
        ></path>
        <path
          d="M125.931 12.4554C122.771 12.4554 120.065 13.4582 117.922 15.3529C116.116 16.9148 115.215 19.2532 115.215 22.1506V34.1842C115.215 34.7432 115.441 35.187 115.778 35.5197C116.116 35.8525 116.568 36.0788 117.132 36.0788C117.695 36.0788 118.148 35.857 118.485 35.5197C118.822 35.187 119.049 34.7388 119.049 34.1842V22.2615C119.049 20.3669 119.612 18.9204 120.628 17.9176C121.081 17.4739 122.998 16.0229 126.046 16.0229C129.094 16.0229 130.896 17.4694 131.348 17.9176C132.364 18.9204 132.928 20.3669 132.928 22.2615V34.2951C132.928 34.8542 133.154 35.2979 133.491 35.6307C133.829 35.9635 134.281 36.1898 134.845 36.1898C135.408 36.1898 135.861 35.9679 136.198 35.6307C136.535 35.2979 136.762 34.8497 136.762 34.2951V22.2615C136.762 19.3641 135.861 17.0257 134.055 15.4638C131.796 13.4582 129.09 12.4554 125.931 12.4554Z"
          fill="white"
        ></path>
        <path
          d="M151.804 13.3474H147.966V9.11432C147.966 8.55524 147.74 8.11152 147.403 7.77874C147.065 7.44595 146.613 7.33502 146.049 7.33502C145.486 7.33502 145.033 7.55688 144.696 7.8941C144.359 8.22689 144.132 8.67504 144.132 9.22968V13.4627H140.294C139.842 13.4627 139.393 13.6846 139.052 14.0218C138.715 14.3546 138.488 14.8027 138.488 15.2465C138.488 15.6902 138.715 16.1383 139.052 16.4711C139.389 16.8039 139.842 16.9148 140.294 16.9148H144.132V34.5214C144.132 35.0805 144.359 35.5242 144.696 35.857C145.033 36.1898 145.486 36.4161 146.049 36.4161C146.613 36.4161 147.065 36.1942 147.403 35.857C147.74 35.5242 147.966 35.0761 147.966 34.5214V16.6885H151.804C152.257 16.6885 152.705 16.5776 153.047 16.2448C153.384 15.912 153.61 15.4639 153.61 15.0202C153.61 14.5764 153.384 14.1283 153.047 13.7955C152.709 13.4627 152.257 13.3518 151.804 13.3518"
          fill="white"
        ></path>
        <path
          d="M249.056 13.791H245.218V9.55798C245.218 8.9989 244.992 8.55519 244.654 8.2224C244.317 7.88962 243.865 7.77869 243.301 7.77869C242.738 7.77869 242.285 8.00054 241.948 8.33777C241.611 8.67055 241.384 9.11871 241.384 9.67335V13.9064H237.546C237.094 13.9064 236.645 14.1282 236.304 14.4655C235.967 14.7983 235.74 15.2464 235.74 15.6901C235.74 16.1338 235.967 16.582 236.304 16.9148C236.641 17.2476 237.094 17.3585 237.546 17.3585H241.384V34.9651C241.384 35.5242 241.611 35.9679 241.948 36.3007C242.285 36.6335 242.738 36.8598 243.301 36.8598C243.865 36.8598 244.317 36.6379 244.654 36.3007C244.992 35.9679 245.218 35.5197 245.218 34.9651V17.1366H249.056C249.544 17.1189 249.957 17.0257 250.299 16.6929C250.636 16.3601 250.862 15.912 250.862 15.4683C250.862 15.0246 250.636 14.5764 250.299 14.2436C249.961 13.9108 249.509 13.7999 249.056 13.7999"
          fill="white"
        ></path>
        <path
          d="M299.245 13.791H295.407V9.55798C295.407 8.9989 295.181 8.55519 294.843 8.2224C294.502 7.88962 294.054 7.77869 293.49 7.77869C292.927 7.77869 292.474 8.00054 292.137 8.33777C291.8 8.67055 291.573 9.11871 291.573 9.67335V13.9064H287.74C287.287 13.9064 286.839 14.1282 286.497 14.4655C286.16 14.7983 285.934 15.2464 285.934 15.6901C285.934 16.1338 286.16 16.582 286.497 16.9148C286.834 17.2476 287.287 17.3585 287.74 17.3585H291.573V34.9651C291.573 35.5242 291.8 35.9679 292.137 36.3007C292.474 36.6335 292.927 36.8598 293.49 36.8598C294.054 36.8598 294.506 36.6379 294.843 36.3007C295.181 35.9679 295.407 35.5197 295.407 34.9651V17.1366H299.245C299.698 17.1366 300.146 17.0257 300.487 16.6929C300.825 16.3601 301.051 15.912 301.051 15.4683C301.051 15.0246 300.825 14.5764 300.487 14.2436C300.15 13.9108 299.809 13.7999 299.245 13.7999"
          fill="white"
        ></path>
        <path
          d="M170.062 13.0145C170.062 13.0145 168.482 12.5708 166.45 12.5708C163.291 12.5708 160.584 13.5736 158.441 15.4683C156.635 17.0301 155.734 19.3685 155.734 22.266V34.2995C155.734 34.8586 155.961 35.3023 156.298 35.6351C156.635 35.9679 157.088 36.1942 157.651 36.1942C158.215 36.1942 158.667 35.9723 159.005 35.6351C159.342 35.3023 159.568 34.8542 159.568 34.2995V22.266C159.568 20.3713 160.132 18.9248 161.148 17.922C161.6 17.4783 163.517 15.9164 166.565 16.0273C167.466 16.0273 169.161 16.3601 169.161 16.3601C169.512 16.4089 169.552 16.4444 169.787 16.4799C170.79 16.6397 171.531 15.7744 171.531 14.7716C171.531 13.6845 170.741 13.2408 170.062 13.0145Z"
          fill="white"
        ></path>
        <path
          d="M204.321 24.5999C204.321 26.1618 203.984 27.7193 203.194 28.9439C202.516 30.1686 201.499 31.2823 200.146 31.9523C198.904 32.6223 197.439 33.066 195.86 33.066C194.28 33.066 192.927 32.7332 191.574 31.9523C190.331 31.1714 189.315 30.1686 188.525 28.9439C187.851 27.6083 187.398 26.1574 187.398 24.5999C187.398 23.0425 187.735 21.4806 188.525 20.256C189.204 18.9204 190.22 17.9176 191.574 17.2476C192.816 16.4666 194.28 16.1339 195.86 16.1339C197.439 16.1339 198.793 16.4666 200.146 17.2476C201.388 18.0285 202.405 19.0313 203.194 20.256C203.984 21.4806 204.321 23.0425 204.321 24.5999ZM202.178 14.2392C200.372 13.2364 198.34 12.6773 195.971 12.6773C193.375 12.6773 189.763 13.6801 187.509 16.6885L187.398 5.20959C187.398 4.65051 187.172 4.2068 186.835 3.87401C186.497 3.54123 186.045 3.4303 185.481 3.4303C184.918 3.4303 184.465 3.65216 184.128 3.98938C183.791 4.32216 183.564 4.77032 183.564 5.32496L183.791 24.8262C183.791 27.0537 184.354 28.9483 185.37 30.7321C186.386 32.5158 187.851 33.9623 189.772 34.9651C191.578 35.9679 193.721 36.527 195.98 36.527C198.238 36.527 200.266 35.9679 202.187 34.9651C203.993 33.9623 205.457 32.5158 206.589 30.7321C207.605 28.9483 208.168 26.8318 208.168 24.7153C208.168 22.4879 207.605 20.4823 206.589 18.6985C205.462 16.6929 203.993 15.3574 202.187 14.2436"
          fill="white"
        ></path>
        <path
          d="M232.275 13.7911C231.937 13.4583 231.485 13.3473 231.032 13.3473C230.469 13.3473 230.016 13.5692 229.679 13.9064C229.342 14.2392 229.115 14.6874 229.115 15.242V27.2755C229.115 29.1702 228.552 30.6167 227.536 31.6195C227.083 32.0632 225.166 33.5142 222.118 33.5142C219.185 33.6251 217.268 32.0677 216.816 31.6195C215.8 30.6167 215.236 29.1702 215.236 27.2755V15.0201C215.236 14.4611 215.01 14.0173 214.673 13.6846C214.331 13.3518 213.883 13.2408 213.319 13.2408C212.756 13.2408 212.303 13.4627 211.966 13.7999C211.629 14.1327 211.402 14.5809 211.402 15.1355V27.1691C211.402 30.0665 212.308 32.4049 214.109 33.9668C216.252 35.9724 218.963 36.8642 222.122 36.8642C225.282 36.8642 227.988 35.8614 230.132 33.9668C231.937 32.4049 232.838 30.0665 232.838 27.1691V15.0246C232.838 14.5809 232.612 14.1327 232.275 13.7999"
          fill="white"
        ></path>
        <path
          d="M56.467 11.9008C56.0144 11.4571 55.4509 11.2308 54.8873 11.2308C54.3238 11.2308 53.8313 11.3994 53.3077 11.9008L50.1485 15.0201V14.5764C50.1485 11.679 49.2477 8.78151 47.779 6.44313C45.0679 2.31658 38.9757 -1.25089 32.2046 0.421915C28.7082 1.31378 26.6759 3.31937 25.3226 4.99218C25.2561 5.07649 25.154 5.06317 25.0963 4.99218C23.743 3.31937 21.7108 1.31378 18.2143 0.421915C16.4394 0.0846921 15.6186 0.0891292 14.4915 0.0891292C9.18914 0.0891292 4.67656 3.09752 2.41805 6.54962C-0.514908 10.898 -1.75731 18.6941 4.45026 26.2727C9.75266 32.7377 23.1795 43.4312 23.743 43.8794C24.1956 44.2121 24.5328 44.4384 24.9854 44.4384C25.438 44.4384 25.7663 44.2476 26.2278 43.8794C26.6804 43.5466 37.7555 34.8542 43.7368 28.3937C44.3225 27.808 43.9409 27.3909 43.5948 27.0448L41.465 24.9416C41.1322 24.5866 40.5953 24.6354 40.2536 24.9727C36.0605 29.2678 26.1834 37.6807 24.9943 38.6613C24.9233 38.719 24.8257 38.719 24.7547 38.6613C23.4634 37.5786 12.2197 28.1009 8.2884 23.2732C3.77582 17.8111 4.45026 12.4643 6.59784 9.23409C7.95117 7.33943 11.6739 3.99826 16.8654 5.22291C19.5721 5.89292 21.378 7.67222 22.7313 9.01224C23.7474 10.015 24.4219 10.685 25.1008 10.685C25.7796 10.685 26.5695 9.9041 27.4702 9.01224C28.8235 7.67665 30.7448 5.89292 33.3361 5.22291C38.4122 3.88733 42.2503 7.2285 43.6037 9.23409C44.7307 10.796 45.1656 12.8016 45.1833 14.9181V15.3618L41.7977 12.0206C41.3452 11.5769 40.7816 11.3506 40.2181 11.3506C39.6546 11.3506 39.0911 11.5725 38.6385 12.0206C37.7378 12.9125 37.7378 14.2481 38.6385 15.1399L45.9731 22.2704C46.4257 22.7142 46.9892 22.9404 47.5527 22.9404C48.1163 22.9404 48.6798 22.7186 49.1324 22.2704L56.467 15.029C56.9196 14.5853 57.1459 14.0262 57.1459 13.4671C57.1459 12.9081 56.9196 12.3534 56.467 11.9053"
          fill="white"
        ></path>
      </svg>
    </a>
    <div class="wp-cto-header__menu">
      <nav class="wp-cto-header__nav">
        <a
          routerLink="/"
          class="wp-cto-header__menu-item wp-cto-header__nav-item"
          >Creators</a
        >
        <a
          href="https://about.contribute.to/business"
          class="wp-cto-header__menu-item wp-cto-header__nav-item"
          >Business</a
        >
        <ng-container *ngIf="state.loggedIn">
          <a
            routerLink="/dashboard"
            class="wp-cto-header__menu-item wp-cto-header__nav-item"
            >Dashboard</a
          >
          <a
            class="wp-cto-header__menu-item wp-cto-header__nav-item"
            (click)="auth.logout()"
            >Log Out</a
          >
        </ng-container>
      </nav>

      <ng-container *ngIf="!state.loggedIn">
        <div class="wp-cto-header__account-buttons">
          <button
            data-testid="signup-link"
            type="button"
            class="wp-cto-header__menu-item wp-cto-header__button wp-cto-button wp-cto-button--small wp-cto-button--white"
            (click)="auth.signup()"
          >
            Sign up
          </button>
          <button
            data-testid="login-link"
            type="button"
            class="wp-cto-header__menu-item wp-cto-header__button wp-cto-button wp-cto-button--small wp-cto-button--white"
            (click)="auth.login()"
          >
            Log in
          </button>
        </div>
      </ng-container>

      <div class="wp-cto-header__burger js-burger" (click)="toggleMenu()">
        <button type="button" class="wp-cto-burger">
          <span class="wp-cto-burger__bar"></span>
        </button>
      </div>
    </div>
  </div>

  <div class="wp-cto-header__wrapper wp-cto-header__wrapper--mobile">
    <div class="wp-cto-header__menu">
      <nav class="wp-cto-header__nav">
        <a
          routerLink="/"
          class="wp-cto-header__menu-item is-active wp-cto-header__nav-item"
          >Creators</a
        >
        <a
          href="https://about.contribute.to/business"
          class="wp-cto-header__menu-item is-active wp-cto-header__nav-item"
          >Business</a
        >
        <ng-container *ngIf="state.loggedIn">
          <a
            routerLink="/dashboard"
            class="wp-cto-header__menu-item is-active wp-cto-header__nav-item"
            >Dashboard</a
          >
          <a
            class="wp-cto-header__menu-item is-active wp-cto-header__nav-item"
            (click)="auth.logout()"
            >Log Out</a
          >
        </ng-container>
      </nav>
      <ng-container *ngIf="!state.loggedIn">
        <div class="wp-cto-header__account-buttons">
          <button
            type="button"
            class="wp-cto-header__menu-item wp-cto-header__button wp-cto-button wp-cto-button--small wp-cto-button--white"
            (click)="auth.signup()"
          >
            Sign up
          </button>
          <button
            type="button"
            class="wp-cto-header__menu-item wp-cto-header__button wp-cto-button wp-cto-button--small wp-cto-button--white"
            (click)="auth.login()"
          >
            Log in
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</header>

<header *ngIf="state.shown && !state.navigation" class="header">
  <div class="container container--small">
    <div class="header__content">
      <a routerLink="/" class="header__brand">
        <ng-container *ngIf="!state.navigation">
          <img
            src="/assets/images/cto-logo-full-dark.svg"
            alt="Contribute.to Logo"
            class="header__logo header__logo--desktop"
          />
          <img
            src="/assets/images/cto-logo-heart-dark.svg"
            alt="Contribute.to Logo"
            class="header__logo header__logo--mobile"
          />
          <span class="header__brand-text">Dashboard</span>
        </ng-container>
      </a>
      <!-- TODO: Hide navigation on dashboard. -->
      <nav
        *ngIf="!state.maintenance && state.navigation"
        class="header__navigation"
      >
        <ul class="header__menu">
          <!--          <li class="header__menu-item">-->
          <!--            <a routerLink="/about" class="button button--link-secondary">About Us</a>-->
          <!--          </li>-->
          <ng-container *ngIf="state.loggedIn">
            <li class="header__menu-item">
              <a routerLink="/dashboard" class="button button--link-secondary"
                >Dashboard</a
              >
            </li>
            <li class="header__menu-item">
              <a class="button button--link-secondary" (click)="auth.logout()"
                >Log Out</a
              >
            </li>
          </ng-container>
          <ng-container *ngIf="!state.loggedIn">
            <li class="header__menu-item">
              <a class="button button--primary" (click)="auth.signup()"
                >Create My Card!</a
              >
            </li>
            <li class="header__menu-item">
              <a class="button button--link-secondary" (click)="auth.login()"
                >Log In</a
              >
            </li>
          </ng-container>
        </ul>
      </nav>
      <button
        *ngIf="!state.navigation"
        class="header__hamburger"
        (click)="toggleSidebar()"
      >
        <svg
          *ngIf="!sidebarOpened"
          width="27"
          height="19"
          viewBox="0 0 27 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0H27V3H0V0ZM0 8H27V11H0V8ZM27 16H0V19H27V16Z"
            fill="#363740"
          />
        </svg>
        <svg
          *ngIf="sidebarOpened"
          width="19"
          height="19"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.0821739 12.9175C0.137565 12.9723 0.209912 13 0.282259 13C0.354606 13 0.426953 12.9723 0.482343 12.9175L6.49958 6.89967L12.5168 12.9169C12.5722 12.9723 12.6446 13 12.7169 13C12.7893 13 12.8616 12.9723 12.917 12.9175C13.0272 12.8073 13.0272 12.6281 12.917 12.5179L6.89919 6.50007L12.917 0.482832C13.0272 0.372615 13.0272 0.193443 12.917 0.0832273C12.8068 -0.0269889 12.6276 -0.0269889 12.5174 0.0832273L6.49958 6.10047L0.482343 0.0826621C0.372127 -0.027554 0.192955 -0.027554 0.0827391 0.0826621C-0.0274771 0.192878 -0.0274771 0.37205 0.0827391 0.482267L6.09998 6.50007L0.0821739 12.5173C-0.0280423 12.6281 -0.0280423 12.8067 0.0821739 12.9175Z"
            fill="#5B5B5B"
          />
        </svg>
      </button>
    </div>
  </div>
</header>
<!-- OLD HEADER END -->
