export const CardSchema = {
  title: {
    minLength: 3,
    maxLength: 64,
  },
  summary: {
    minLength: 4,
    maxLength: 1000,
  },
}
