import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Artist } from '../../../core/data/artist.data';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthService } from '../../../core/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { SystemType } from '../../../core/enums/system-type';
import { UtilityService } from '../../../core/services/utility.service';
import Cleave from 'cleave.js';
import { environment } from '../../../../environments/environment';
import { PaymentService } from '../../../core/services/payment.service';
import { CardResponse } from '../../../core/interfaces/card.interface';
import { first } from 'rxjs/operators';
import { CardService } from '../../../core/services/card.service';

@Component({
  selector: 'cto-shared-card-page',
  templateUrl: './shared-card-page.component.html',
  styleUrls: ['./shared-card-page.component.scss'],
})
export class SharedCardPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  @ViewChild('priceInput', { static: false }) priceInput: ElementRef;

  artist: Artist;

  data: CardResponse = null;

  init;

  state = {
    showEdit: false,
    descriptionLimit: 120,
    expanded: false,
    animationExpanded: false,
    collectionDescription: false,
  };

  year = new Date().getFullYear();
  copied = false;

  video$ = new BehaviorSubject<string>('');
  image$ = new BehaviorSubject<string>('');
  overlay$ = new BehaviorSubject<boolean>(true);
  share$ = new BehaviorSubject<boolean>(false);
  contribute$ = new BehaviorSubject<boolean>(false);
  custom$ = new BehaviorSubject<boolean>(false);
  pay$ = new BehaviorSubject<boolean>(false);

  config: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
  };

  dummy = document.createElement('div');

  prices = [{ value: 5 }, { value: 50 }, { value: 100 }];

  price;
  customPrice;
  cleave;
  checkout: any;

  theme = document.querySelector('meta[name=theme-color]');

  constructor(
    private route: ActivatedRoute,
    private global: GlobalService,
    public auth: AuthService,
    private cdr: ChangeDetectorRef,
    private utility: UtilityService,
    private payment: PaymentService,
    private card: CardService
  ) {
    this.artist = route.snapshot.data as Artist;
  }

  ngOnInit(): void {
    this.global.hideHeader();
    this.theme.setAttribute('content', this.artist.collection.color);

    void this.getCardBySlug();
  }

  getCardBySlug() {
    return new Promise((resolve) => {
      this.card
        .getCardBySlug(this.artist.realCard)
        .pipe(first())
        .subscribe(
          (response: CardResponse) => {
            console.log(
              `Got information for card "${this.artist.realCard}":`,
              response
            );

            this.data = response;

            resolve(true);
          },
          () => {
            resolve(true);
          }
        );
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.videoElement?.nativeElement.addEventListener(
        'webkitendfullscreen',
        () => {
          this.closeVideo();
        }
      );

      this.custom$.subscribe((opened) => {
        if (!opened) {
          this.cleave = null;
          return;
        }

        setTimeout(() => {
          this.cleave = new Cleave(this.priceInput.nativeElement, {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          });
        }, 100);
      });
    }, 100);
  }

  ngOnDestroy() {
    this.global.showHeader();
    this.theme.setAttribute('content', '#ffffff');
  }

  inlineText(text: string) {
    this.dummy.innerHTML = text;

    return (this.dummy.textContent || this.dummy.innerText || '')
      .replace(/[\s\n]+/g, ' ')
      .trim();
  }

  get description() {
    if (!this.isExapndable) {
      return this.artist.description;
    }

    return this.state.expanded
      ? this.artist.description.replace(/\n/g, '<br>')
      : this.artist.description.substring(0, this.state.descriptionLimit);
  }

  get isExapndable() {
    return this.artist.description.length >= this.state.descriptionLimit;
  }

  play() {
    this.video$.next(this.artist.video);
  }

  closeVideo() {
    this.video$.next('');
  }

  share() {
    this.share$.next(true);
  }

  closeShare() {
    this.share$.next(false);
  }

  closeContribute() {
    this.contribute$.next(false);
  }

  contribute() {
    this.contribute$.next(true);
  }

  get url() {
    return window.location.origin + '/' + (this.artist?.slug || '');
  }

  get facebook() {
    return (
      'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(this.url)
    );
  }

  get twitter() {
    return 'https://twitter.com/share?url=' + encodeURIComponent(this.url);
  }

  get whatsapp() {
    return 'https://wa.me/?text=' + encodeURIComponent(this.url);
  }

  get email() {
    if (this.utility.isSystemType(SystemType.iOS)) {
      return 'sms://?&body=' + encodeURIComponent(this.url);
    }

    return (
      'mailto:?body=' +
      encodeURIComponent(this.url) +
      '&subject=' +
      encodeURIComponent('Contribute.to')
    );
  }

  copy() {
    this.input.nativeElement.select();
    this.input.nativeElement.setSelectionRange(0, 99999);

    document.execCommand('copy');

    this.copied = true;

    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  toggleOverlay() {
    this.overlay$.next(!this.overlay$.value);
  }

  toggleCollectionDescription() {
    if (!this.artist?.collection?.readMore) {
      return;
    }

    this.state.collectionDescription = !this.state.collectionDescription;
  }

  onSlideChange() {
    if (!this.init) {
      this.init = true;
      return;
    }

    this.overlay$.next(false);
    this.cdr.detectChanges();
  }

  isIndex(index) {
    if (!this.swiper?.swiperRef) {
      return false;
    }

    return this.swiper.swiperRef.realIndex === index;
  }

  openImage() {
    if (!this.swiper?.swiperRef) {
      return false;
    }

    this.image$.next(this.artist.images[this.swiper.swiperRef.realIndex]);
  }

  toggleDescription() {
    if (!this.state.animationExpanded) {
      this.state.animationExpanded = true;

      setTimeout(() => {
        this.state.expanded = true;
      }, 0);

      return;
    }

    this.state.expanded = false;
    this.state.animationExpanded = false;
  }

  closeImage() {
    this.image$.next('');
  }

  selectPrice(price) {
    this.price = price;
    this.customPrice = null;
  }

  customContribute() {
    this.custom$.next(true);
    this.price = null;
  }

  cancelCustom() {
    this.custom$.next(false);
  }

  pay() {
    const price = parseFloat(
      this.custom$.value ? this.cleave.getRawValue() : this.price?.value
    );

    if (!price) {
      return;
    }

    const priceInCents = price * 100;

    this.closeContribute();
    this.pay$.next(true);

    this.payment
      .getSingleUseToken({
        amount: priceInCents,
        currency: 'USD',
        merchant_id: this.artist.merchant_id,
      })
      .subscribe(async (res) => {
        console.log('res', res);
        this.checkout = new (window as any).ContributeTo.GuestCheckout({
          env: environment.url.tapper.includes('sbx')
            ? 'sbx'
            : environment.url.tapper.includes('stg')
            ? 'stg'
            : 'prod',
        });
        await this.checkout.load();

        this.checkout.render('#payment-form-1', {
          singleUseToken: res.single_use_token,
          showThankYouWidget: true,
          allowGuestRegistration: false,
          clientId: environment.authConfig.clientId,
          paymentRequestOptions: {
            country: 'US',
            currency: 'usd',
            total: {
              amount: priceInCents,
              label: 'Contribute to: ' + this.artist.name,
            },
          },
          cardOptions: {
            hidePostalCode: true,
          },
          onPaymentFail: (paymentIntent, error) => {
            console.log('payment error', paymentIntent, error);
            this.closePayment();
          },
          onPaymentSuccess: (paymentIntent) => {
            console.log('payment success', paymentIntent);
            // this.state.paymentSuccess = true;
            // this.state.success = true;

            // setTimeout(() => {
            //   this.closePayment();
            //   this.state.success = false;
            // }, 3000);
          },
        });
      });
  }

  closePayment() {
    this.pay$.next(false);
    this.checkout = null;
  }

  slidePrev() {
    this.swiper?.swiperRef?.slidePrev();
    this.overlay$.next(true);
  }

  slideNext() {
    this.swiper?.swiperRef?.slideNext();
    this.overlay$.next(true);
  }
}
