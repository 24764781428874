import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) { }

  @Output() clickOutside = new EventEmitter<Event>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: Event, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if(clickedInside) {
      return;
    }

    this.clickOutside.emit(event);
  }
}
