import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

export interface MailchimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailchimpService {
  constructor(
    private http: HttpClient,
  ) { }

  subscribe({ email }): Observable<MailchimpResponse> {
    const params = new HttpParams()
      .set('EMAIL', email)
      .set(environment.mailchimp.hidden, '');

    const url = `${environment.mailchimp.host}/subscribe/post-json?u=${environment.mailchimp.u}&amp;id=${environment.mailchimp.id}&${params.toString()}`;

    return this.http.jsonp<MailchimpResponse>(url, 'c');
  }
}
