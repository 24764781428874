import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Card, CardResponse} from '../../../core/interfaces/card.interface';
import {EditCardModalService} from '../../../core/services/edit-card-modal.service';
import {CardService} from '../../../core/services/card.service';
import {AuthService} from '../../../core/services/auth.service';
import {GlobalService} from '../../../core/services/global.service';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {UserProfile} from '../../../core/interfaces/user.interface';
import {BehaviorSubject, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {EditProfileService} from '../../components/edit-profile/edit-profile.service';
import {EditGalleryService} from '../../components/edit-gallery/edit-gallery.service';
import {EditContributionsService} from '../../components/edit-contributions/edit-contributions.service';

@Component({
  selector: 'cto-canonical-card-page',
  templateUrl: './canonical-card-page.component.html',
  styleUrls: ['./canonical-card-page.component.scss']
})
export class CanonicalCardPageComponent implements OnInit, OnDestroy {
  @Input() data: CardResponse = null;

  state = {
    showEdit: false,
    loggedIn: false,
  }

  cardSub: Subscription;
  userSub: Subscription;

  year = new Date().getFullYear();

  dropdown = false;

  constructor(
    private profileService: EditProfileService,
    private galleryService: EditGalleryService,
    private contributionService: EditContributionsService,
    private cards: CardService,
    public auth: AuthService,
    private global: GlobalService,
    private store: StoreService,
  ) { }

  ngOnInit(): void {
    this.global.hideHeader();

    this.cardSub = this.store.get<Card>(StoreKey.Card).subscribe(card => {
      this.state.showEdit = card?.id === this.data.card.id;
    });

    this.userSub = this.store.get<UserProfile>(StoreKey.UserProfile).subscribe(profile => {
      this.state.loggedIn = !!profile;
    });
  }

  ngOnDestroy() {
    this.global.showHeader();
    this.cardSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  onClickOutsideDropdown() {
    this.dropdown = false;
  }

  openEditProfile() {
    this.profileService.open();
  }

  openEditGallery() {
    this.galleryService.open();
  }

  openEditContributions() {
    this.contributionService.open();
  }
}
