import {Component, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {SlugService} from '../../../core/services/slug.service';
import {HeicFileConverterService} from '../../../core/services/heic-file-converter.service';
import {FileService} from '../../../core/services/file.service';
import {CardService} from '../../../core/services/card.service';
import {Card, SocialItem} from '../../../core/interfaces/card.interface';
import {first} from 'rxjs/operators';
import {EditProfileService} from './edit-profile.service';
import {RefreshService} from '../../../core/services/refresh.service';
import {CardSchema} from '../../../core/schemas/card.schema';
import {GlobalService} from '../../../core/services/global.service';
import {EditProfileScreenComponent} from '../edit-profile-screen/edit-profile-screen.component';

@Component({
  selector: 'cto-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  @ViewChild(EditProfileScreenComponent) profile: EditProfileScreenComponent;

  opened = false;

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private service: EditProfileService,
    public global: GlobalService,
  ) { }

  ngOnInit(): void {
    this.service.opened.subscribe(opened => {
      this.opened = opened;

      if(!opened) {
        return;
      }
    });
  }

  profileSuccess() {
    this.setLoading(false);
    this.close();
  }

  async submitProfile() {
    await this.profile?.submitProfile();
  }

  close() {
    this.service.close();
  }

  cancel() {
    this.close();
  }

  setLoading(state: boolean) {
    this.loading$.next(state);
  }
}
