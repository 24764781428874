import { Component, OnInit } from '@angular/core';
import {Card} from '../../../core/interfaces/card.interface';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {EditContributionsService} from './edit-contributions.service';
import {AbstractControl, FormArray, FormControl, Validators} from '@angular/forms';
import {CardService} from '../../../core/services/card.service';
import {BehaviorSubject} from 'rxjs';
import {RefreshService} from '../../../core/services/refresh.service';

@Component({
  selector: 'cto-edit-contributions',
  templateUrl: './edit-contributions.component.html',
  styleUrls: ['./edit-contributions.component.scss']
})
export class EditContributionsComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(false);
  opened = false;
  contributions: FormArray;

  constructor(
    private service: EditContributionsService,
    private store: StoreService,
    private card: CardService,
    private refresh: RefreshService,
  ) { }

  ngOnInit(): void {
    this.service.opened.subscribe(async opened => {
      this.opened = opened;

      if(!opened) {
        return;
      }

      const contributions = this.store.value<Card>(StoreKey.Card)?.contribution_amounts || [5, 10, 20];

      this.contributions = new FormArray(
        contributions.map(
          contribution => new FormControl(
            contribution, [Validators.required, Validators.min(2)]
          )
        )
      );

      this.loading$.next(false);

      // this.initSortable();
    });
  }

  atf(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  cancel() {
    this.loading$.next(false);
    this.service.close();
  }

  save() {
    if(this.contributions.invalid) {
      return;
    }

    this.loading$.next(true);

    const contribution_amounts = this.contributions.value.map(contribution => parseInt(contribution));

    this.card.updateCardV2({
      contribution_amounts
    }).subscribe(() => {
      setTimeout(() => {
        this.refresh.refresh();
        this.service.close();
      }, 1000);
    });
  }
}
