export const homepage: any = {
  cardSlider: [
    {
      url: 'https://contribute.to/blairehawes',
      image: '/assets/images/homepage/blaire_hawes.webp',
    },
    {
      url: 'https://contribute.to/mimihaddon',
      image: '/assets/images/homepage/mimi_haddon.webp',
    },
    {
      url: 'https://contribute.to/andrewblauschild',
      image: '/assets/images/homepage/andrew_blauschild.webp',
    },
    {
      url: 'https://contribute.to/downsandtowns',
      image: '/assets/images/homepage/downs_towns.webp',
    },
    {
      url: 'https://contribute.to/halaburda',
      image: '/assets/images/homepage/philippe_halaburda.webp',
    },
    {
      url: 'https://contribute.to/margretwibmer',
      image: '/assets/images/homepage/margret_wibmer.webp',
    },
  ],
  supporterSlider: [
    {
      image: '/assets/images/homepage/blaire.webp',
      title: 'Blaire Hawes',
      description: `Because of <a href="https://contribute.to">Contribute.to</a>, I am now able to create a piece that has been living in my imagination for probably 20 years. Hidden in my “maybe someday” file of my brain.”`,
      url: 'https://contribute.to/blairehawes',
    },
    {
      image: '/assets/images/homepage/minLee.webp',
      title: 'Min Lee',
      description: `I didn't really believe that I could raise more than $1,000 with matching, but I made it! It gave me confidence about myself and my work.`,
      url: 'https://contribute.to/minlee',
    },
    {
      image: '/assets/images/homepage/GrantBeran.webp',
      title: 'Grant Beran',
      description: `I was stunned, delighted and humbled by how successful my campaign to raise money was. The whole thing seemed so magical. For me, this campaign has been like my birthday and Christmas all rolled into one!`,
      url: 'https://contribute.to/grantberan',
    },
  ]
}
