<div class="media-gallery" [class.is-opened]="state.opened">
  <ng-container *ngIf="gallery?.length < 2">
    <div class="media-gallery__image-wrapper">
      <img [src]="getSingleImageSource()" class="media-gallery__image" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="gallery?.length >= 2">
    <swiper #swiper class="media-gallery__slider" [config]="config" (slideChange)="onSlideChange()">
      <ng-container *ngFor="let element of gallery; let i = index">
        <ng-template swiperSlide>
          <div class="media-gallery__image-wrapper">
            <ng-container [ngSwitch]="element.type">
              <ng-container *ngSwitchCase="'image'">
                <img  [src]="element.item.url || element.item.cropped_url || 'assets/images/hero-default.png'" alt="" class="media-gallery__image">
              </ng-container>
              <ng-container *ngSwitchCase="'video'">
                <cto-video-element *ngIf="isActiveIndex(i)" [url]="element.item.url" [poster]="element.item.poster_image_url || 'assets/images/hero-default.png'"></cto-video-element>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </swiper>
    <div class="shared-card__button-prev shared-card__button--clickable" (click)="slidePrev()" style="z-index: 100;"></div>
    <div class="shared-card__button-next shared-card__button--clickable" (click)="slideNext()" style="z-index: 100;"></div>
  </ng-container>
  <button type="button" class="media-gallery__close" (click)="close()">
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_491_18)">
        <path d="M4.70711 1.29297L25.7071 22.293M25.7071 1.70718L4.70711 22.7072" stroke="white" stroke-width="2"/>
      </g>
      <defs>
        <filter id="filter0_d_491_18" x="0" y="0.585938" width="30.4142" height="30.8281" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_491_18"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_491_18" result="shape"/>
        </filter>
      </defs>
    </svg>
  </button>
</div>
