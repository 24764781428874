import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GlobalService } from '../../../core/services/global.service';
import { SwiperOptions } from 'swiper';
import { BehaviorSubject } from 'rxjs';
import { Artist } from '../../../core/data/artist.data';
import { ActivatedRoute } from '@angular/router';
import { SwiperComponent } from 'swiper/angular';
import { SystemType } from '../../../core/enums/system-type';
import { UtilityService } from '../../../core/services/utility.service';
import Cleave from 'cleave.js';
import { environment } from '../../../../environments/environment';
import { PaymentService } from '../../../core/services/payment.service';

@Component({
  selector: 'cto-media-card-page',
  templateUrl: './media-card-page.component.html',
  styleUrls: ['./media-card-page.component.scss'],
})
export class MediaCardPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('priceInput', { static: false }) priceInput: ElementRef;

  overlay$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<boolean>(false);
  share$ = new BehaviorSubject<boolean>(false);
  contribute$ = new BehaviorSubject<boolean>(false);
  custom$ = new BehaviorSubject<boolean>(false);
  fullScreen$ = new BehaviorSubject<boolean>(true);
  video$ = new BehaviorSubject<string>('');
  image$ = new BehaviorSubject<string>('');
  pay$ = new BehaviorSubject<boolean>(false);

  copied = false;

  dummy = document.createElement('div');

  config: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
  };

  artist: Artist;
  init = false;

  prices = [{ value: 5 }, { value: 50 }, { value: 100 }];

  price;
  customPrice;
  cleave;
  checkout;

  constructor(
    private global: GlobalService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private utility: UtilityService,
    private payment: PaymentService
  ) {
    this.artist = route.snapshot.data as Artist;
  }

  ngOnInit(): void {
    this.global.hideHeader();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.videoElement?.nativeElement.addEventListener(
        'webkitendfullscreen',
        () => {
          this.closeVideo();
        }
      );

      this.custom$.subscribe((opened) => {
        if (!opened) {
          this.cleave = null;
          return;
        }

        setTimeout(() => {
          this.cleave = new Cleave(this.priceInput.nativeElement, {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          });
        }, 100);
      });
    }, 100);
  }

  ngOnDestroy() {
    this.global.showHeader();
  }

  inlineText(text: string) {
    this.dummy.innerHTML = text;

    return (this.dummy.textContent || this.dummy.innerText || '')
      .replace(/[\s\n]+/g, ' ')
      .trim();
  }

  play() {
    this.video$.next(this.artist.video);
  }

  share() {
    this.share$.next(true);
  }

  closeShare() {
    this.share$.next(false);
  }

  closeUser() {
    this.user$.next(false);
  }

  closeContribute() {
    this.contribute$.next(false);
  }

  contribute() {
    this.contribute$.next(true);
  }

  toggleOverlay() {
    this.overlay$.next(!this.overlay$.value);
  }

  onSlideChange() {
    if (!this.init) {
      this.init = true;
      return;
    }

    this.overlay$.next(false);
    this.cdr.detectChanges();
  }

  user() {
    this.user$.next(true);
  }

  isIndex(index) {
    if (!this.swiper?.swiperRef) {
      return false;
    }

    return this.swiper.swiperRef.realIndex === index;
  }

  get url() {
    return window.location.origin + '/' + (this.artist?.slug || '');
  }

  get facebook() {
    return (
      'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(this.url)
    );
  }

  get twitter() {
    return 'https://twitter.com/share?url=' + encodeURIComponent(this.url);
  }

  get whatsapp() {
    return 'https://wa.me/?text=' + encodeURIComponent(this.url);
  }

  get email() {
    if (this.utility.isSystemType(SystemType.iOS)) {
      return 'sms://?&body=' + encodeURIComponent(this.url);
    }

    return (
      'mailto:?body=' +
      encodeURIComponent(this.url) +
      '&subject=' +
      encodeURIComponent('Contribute.to')
    );
  }

  copy() {
    this.input.nativeElement.select();
    this.input.nativeElement.setSelectionRange(0, 99999);

    document.execCommand('copy');

    this.copied = true;

    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  closeVideo() {
    this.video$.next('');
  }

  closeImage() {
    this.image$.next('');
  }

  openImage() {
    if (!this.swiper?.swiperRef) {
      return false;
    }

    this.image$.next(this.artist.images[this.swiper.swiperRef.realIndex]);
  }

  selectPrice(price) {
    this.price = price;
    this.customPrice = null;
  }

  customContribute() {
    this.custom$.next(true);
    this.price = null;
  }

  cancelCustom() {
    this.custom$.next(false);
  }

  toggleImage() {
    this.fullScreen$.next(!this.fullScreen$.value);
  }

  pay() {
    const price = parseFloat(
      this.custom$.value ? this.cleave.getRawValue() : this.price?.value
    );

    if (!price) {
      return;
    }

    const priceInCents = price * 100;

    this.closeContribute();
    this.pay$.next(true);

    this.payment
      .getSingleUseToken({
        amount: priceInCents,
        currency: 'USD',
        slug: this.artist.realSlug || this.artist.slug,
      })
      .subscribe(async (res) => {
        console.log('res', res);
        this.checkout = new (window as any).ContributeTo.GuestCheckout({
          env: environment.url.tapper.includes('sbx')
            ? 'sbx'
            : environment.url.tapper.includes('stg')
            ? 'stg'
            : 'prod',
        });
        await this.checkout.load();

        this.checkout.render('#payment-form-1', {
          singleUseToken: res.single_use_token,
          showThankYouWidget: true,
          allowGuestRegistration: false,
          clientId: environment.authConfig.clientId,
          paymentRequestOptions: {
            country: 'US',
            currency: 'usd',
            total: {
              amount: priceInCents,
              label: 'Contribute to: ' + this.artist.name,
            },
          },
          cardOptions: {
            hidePostalCode: true,
          },
          onPaymentFail: (paymentIntent, error) => {
            console.log('payment error', paymentIntent, error);
            this.closePayment();
          },
          onPaymentSuccess: (paymentIntent) => {
            console.log('payment success', paymentIntent);
            // this.state.paymentSuccess = true;
            // this.state.success = true;

            // setTimeout(() => {
            //   this.closePayment();
            //   this.state.success = false;
            // }, 3000);
          },
        });
      });
  }

  closePayment() {
    this.pay$.next(false);
    this.checkout = null;
  }
}
