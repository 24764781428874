import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Card} from '../../../core/interfaces/card.interface';
import SwiperCore, {Pagination, SwiperOptions} from 'swiper';
import {BehaviorSubject} from 'rxjs';
import {SystemType} from '../../../core/enums/system-type';
import {UtilityService} from '../../../core/services/utility.service';
import {VideoPlayerService} from '../../../core/services/video-player.service';
import {SwiperComponent} from 'swiper/angular';
import {FormControl, Validators} from '@angular/forms';
import CustomValidators from '../../../core/global/custom-validators';
import {PaymentService} from '../../../core/services/payment.service';
import {environment} from '../../../../environments/environment';
import StringUtils from '../../../core/global/string-utils';
import {GalleryImageItem, GalleryItem} from '../../../core/interfaces/gallery.interface';
import {GalleryVideoItem} from '../../../core/interfaces/gallery.interface/gallery-video-item.interface';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {MediaGalleryService} from '../../../core/services/media-gallery.service';
import {CheckoutService} from '../../../core/services/checkout.service';

SwiperCore.use([Pagination]);

@Component({
  selector: 'cto-canonical-card',
  templateUrl: './canonical-card.component.html',
  styleUrls: ['./canonical-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CanonicalCardComponent implements OnInit {
  @Input() card: Card;
  @Input() gallery: GalleryItem[] = [];

  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  @ViewChild('pagination', { static: false }) paginationRef: ElementRef;
  @ViewChild('checkout', { static: false }) checkoutRef: ElementRef;

  config: SwiperOptions = {
    slidesPerView: 1,
  };

  pagination = {
    dynamicBullets: true,
    dynamicMainBullets: 3,
  };

  state = {
    expanded: false,
    isExpandable: false,
    copied: false,
    init: false,
    descriptionLimit: 105,
    selectedPrice: -1,
    paymentSuccess: false,
  }

  customValue = new FormControl('', [Validators.required, CustomValidators.price(2, 10000)]);

  image$ = new BehaviorSubject<string>('');
  overlay$ = new BehaviorSubject<boolean>(true);
  share$ = new BehaviorSubject<boolean>(false);
  contribute$ = new BehaviorSubject<boolean>(false);
  custom$ = new BehaviorSubject<boolean>(false);
  pay$ = new BehaviorSubject<boolean>(false);

  maxBullets = 3;

  constructor(
    private utility: UtilityService,
    private player: VideoPlayerService,
    private cdr: ChangeDetectorRef,
    private payment: PaymentService,
    private route: ActivatedRoute,
    private mediaGallery: MediaGalleryService,
    private checkout: CheckoutService,
  ) { }

  ngOnInit(): void {
    this.state.isExpandable = this.card.summary.length > this.state.descriptionLimit;
    this.route.queryParams.pipe(first()).subscribe(params => {
      if(!params?.contribute) {
        return;
      }

      this.contribute();
    });
  }

  get description() {
    if(!this.state.isExpandable) {
      return this.card.summary;
    }

    return this.state.expanded ? this.card.summary.replace(/\n/g, '<br>') : this.card.summary.substring(0, this.state.descriptionLimit);
  }

  get url() {
    return window.location.origin + '/' + (this.card?.slug || '');
  }

  get facebook() {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url);
  }

  get twitter() {
    return 'https://twitter.com/share?url=' + encodeURIComponent(this.url);
  }

  get whatsapp() {
    return 'https://wa.me/?text=' + encodeURIComponent(this.url);
  }

  get email() {
    if(this.utility.isSystemType(SystemType.iOS)) {
      return 'sms://?&body=' + encodeURIComponent(this.url);
    }

    return 'mailto:?body=' + encodeURIComponent(this.url) + '&subject=' + encodeURIComponent('Contribute.to');
  }

  get payDisabled(): boolean {
    if(!this.custom$.value) {
      return this.state.selectedPrice < 0;
    }

    return !this.customValue.valid;
  }

  getSingleImageSource() {
    if(!this.gallery.length) {
      return this.card?.cover_image_url || 'assets/images/hero-default.png';
    }

    if(this.gallery[0].type === 'video') {
      return (this.gallery[0].item as GalleryVideoItem).poster_image_url || 'assets/images/hero-default.png';
    }

    return (this.gallery[0].item as GalleryImageItem).cropped_url || (this.gallery[0].item as GalleryImageItem).url || 'assets/images/hero-default.png';
  }

  get isVideoItem(): boolean {
    if(!this.gallery?.length) {
      return false;
    }

    if(!this.swiper?.swiperRef) {
      return this.gallery[0].type === 'video' && !!this.gallery[0].item.url;
    }

    return this.gallery[this.swiper.swiperRef.realIndex].type === 'video' && !!this.gallery[this.swiper.swiperRef.realIndex].item.url;
  }

  get isImageItem(): boolean {
    if(!this.gallery?.length) {
      return false;
    }

    if(!this.swiper?.swiperRef) {
      return this.gallery[0].type === 'image' && !!this.gallery[0].item.url;
    }

    return this.gallery[this.swiper.swiperRef.realIndex].type === 'image' && !!this.gallery[this.swiper.swiperRef.realIndex].item.url;
  }

  toggleOverlay() {
    this.overlay$.next(!this.overlay$.value);
  }

  onSlideChange() {
    if(!this.state.init) {
      this.state.init = true;
      return;
    }

    this.overlay$.next(false);
    this.cdr.detectChanges();
  }

  play() {
    if(!this.gallery.length) {
      return;
    }

    if(this.gallery?.length < 2) {
      this.player.open(this.gallery[0].item.url);
      return;
    }

    if(!this.swiper?.swiperRef) {
      return;
    }

    if(!this.gallery[this.swiper.swiperRef.realIndex].item.url) {
      return;
    }

    this.player.open(this.gallery[this.swiper.swiperRef.realIndex].item.url);
  }

  isIndex(index: number) {
    if(!this.swiper?.swiperRef) {
      return false;
    }

    return this.swiper.swiperRef.realIndex === index;
  }

  bulletTypeClass(index: number) {
    if(!this.swiper?.swiperRef?.pagination?.bullets) {
      return '';
    }

    const bullet = this.swiper.swiperRef.pagination.bullets[index] as any;

    if(bullet.classList.contains('swiper-pagination-bullet-active')) {
      return 'is-active';
    }

    if(bullet.classList.contains('swiper-pagination-bullet-active-prev') || bullet.classList.contains('swiper-pagination-bullet-active-next')) {
      return 'is-next';
    }

    if(!bullet.classList.contains('swiper-pagination-bullet-active-main')) {
      return 'is-hidden';
    }

    return '';
  }

  openImage() {
    // if(!this.swiper?.swiperRef) {
    //   this.image$.next(this.gallery[0].item.url)
    // }
    //
    // this.image$.next(this.gallery[this.swiper.swiperRef.realIndex].item.url);

    console.log(this.swiper?.swiperRef?.realIndex);

    this.mediaGallery.open(this.swiper?.swiperRef?.realIndex || 0);
  }

  toggleDescription() {
    if(!this.state.expanded) {
      setTimeout(() => {
        this.state.expanded = true;
      }, 0);

      return;
    }

    this.state.expanded = false;
  }

  share() {
    this.share$.next(true);
  }

  contribute() {
    this.contribute$.next(true);
  }

  closeShare() {
    this.share$.next(false);
  }

  copy() {
    void navigator.clipboard.writeText(this.url);

    this.state.copied = true;

    setTimeout(() => {
      this.state.copied = false;
    }, 1000);
  }

  closeContribute() {
    this.contribute$.next(false);
    this.state.selectedPrice = -1;
  }

  customContribute() {
    this.state.selectedPrice = -1;
    this.custom$.next(true);
  }

  cancelCustom() {
    this.custom$.next(false);
    this.customValue.patchValue('');
  }

  createCheckoutElement() {
    const id = 'payment-form';

    const element = document.createElement('div');
    element.id = id;

    this.checkoutRef?.nativeElement.appendChild(element);

    return '#' + id;
  }

  pay() {
    this.custom$.next(false);
    this.contribute$.next(false);
    this.pay$.next(true);

    const price = this.customValue.valid ? StringUtils.priceStringToNumber(this.customValue.value) : this.state.selectedPrice;

    if(price < 2) {
      return;
    }

    const priceInCents = Math.ceil(price * 100);

    this.payment.getSingleUseToken({
      amount: priceInCents,
      currency: 'USD',
      merchant_id: this.card.merchant_id,
    }).subscribe(async res => {
      const selector = this.createCheckoutElement();

      this.checkout.render(selector, {
        singleUseToken: res.single_use_token,
        showThankYouWidget: true,
        allowGuestRegistration: false,
        clientId: environment.authConfig.clientId,
        paymentRequestOptions: {
          country: 'US',
          currency: 'usd',
          total: {
            amount: priceInCents,
            label: 'Contribute to: ' + this.card.title,
          },
        },
        cardOptions: {
          hidePostalCode: true,
        },
        onPaymentFail: (paymentIntent, error) => {
          console.log('payment error', paymentIntent, error);
          this.closePayment();
        },
        onPaymentSuccess: paymentIntent => {
          console.log('payment success', paymentIntent);

          this.state.selectedPrice = -1;
          this.customValue.patchValue('');

          this.state.paymentSuccess = true;
          // this.state.success = true;

          // setTimeout(() => {
          //   this.closePayment();
          //   this.state.success = false;
          // }, 3000);
        },
      });
    });
  }

  closePayment() {
    this.checkout?.destroy();

    this.pay$.next(false);
    this.state.selectedPrice = -1;
    this.customValue.patchValue('');
    this.state.paymentSuccess = false;
  }

  closeImage() {
    this.image$.next('');
  }

  selectPrice(price: number) {
    this.state.selectedPrice = price;
  }

  slidePrev() {
    this.swiper?.swiperRef?.slidePrev();
    this.overlay$.next(true);
  }

  slideNext() {
    this.swiper?.swiperRef?.slideNext();
    this.overlay$.next(true);
  }
}
