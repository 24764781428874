import { Injectable } from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {GalleryVideoItem} from '../interfaces/gallery.interface/gallery-video-item.interface';
import {CroppedImageData} from '../../shared/components/image-cropper/image-cropper.component';
import {first, tap} from 'rxjs/operators';
import {ImageCropperOptions} from '../../shared/components/image-cropper/image-cropper.service';

@Injectable({
  providedIn: 'root'
})
export class VideoCoverService {
  options$ = new BehaviorSubject<{ item: GalleryVideoItem, subject$: ReplaySubject<CroppedImageData> } | null>(null);

  constructor() { }

  open(item: GalleryVideoItem) {
    const subject$ = new ReplaySubject<any>(1);

    this.options$.next({ item, subject$ });

    return subject$.pipe(first(), tap(() => this.close()));
  }

  close() {
    this.options$.next(null);
  }
}
