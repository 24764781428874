import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './shared/pages/homepage/homepage.component';
import { UserFormComponent } from './shared/pages/user-form/user-form.component';
import { AboutComponent } from './shared/pages/about/about.component';
import { DashboardResolver } from './core/resolvers/dashboard.resolver';
import { AuthGuard } from './core/guards/auth.guard';
import { RegisterResolver } from './core/resolvers/register.resolver';
import { FaqComponent } from './shared/pages/faq/faq.component';
import { MediaCardPageComponent } from './shared/pages/media-card-page/media-card-page.component';
import { ARTISTS, SHARED } from './core/data/artist.data';
import { SharedCardPageComponent } from './shared/pages/shared-card-page/shared-card-page.component';
import { ArtventComponent } from './shared/pages/artvent/artvent.component';
import { CanonicalCardPageWrapperComponent } from './shared/pages/canonical-card-page-wrapper/canonical-card-page-wrapper.component';
import { CardPageResolver } from './core/resolvers/card-page.resolver';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { RedirectActions } from './core/enums/redirect-actions';
import {MaintenanceComponent} from './shared/pages/maintenance/maintenance.component';
import {environment} from '../environments/environment';

const staticArtistRoutes = Object.keys(ARTISTS).map(slug => ({
  path: slug,
  component: MediaCardPageComponent,
  data: { ...ARTISTS[slug], slug },
}));

const staticSharedRoutes = Object.keys(SHARED).map(slug => ({
  path: slug,
  component: SharedCardPageComponent,
  data: { ...SHARED[slug], slug },
}));

const routes: Routes = environment.maintenance ? [
  {
    path: '',
    component: MaintenanceComponent,
    pathMatch: 'full',
    // canActivate: [ RedirectGuard ],
  },
  {
    path: '**',
    redirectTo: '',
  }
] : [
  {
    path: '',
    component: HomepageComponent,
    pathMatch: 'full',
    // canActivate: [ RedirectGuard ],
  },
  {
    path: 'login',
    component: RedirectComponent,
    data: {
      action: RedirectActions.Login,
    }
  },
  {
    path: 'create',
    component: RedirectComponent,
    data: {
      action: RedirectActions.Create,
    }
  },
  {
    path: 'signup',
    component: RedirectComponent,
    data: {
      action: RedirectActions.Create,
    }
  },
  {
    path: 'register',
    component: UserFormComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      data: RegisterResolver,
    }
  },
  {
    path: 'artvent',
    component: ArtventComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
    resolve: {
      data: DashboardResolver,
    }
  },
  ...staticSharedRoutes,
  ...staticArtistRoutes,
  {
    path: ':handle',
    component: CanonicalCardPageWrapperComponent,
    resolve: {
      data: CardPageResolver,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [DashboardResolver, CardPageResolver],
})
export class AppRoutingModule { }
