import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {CardService} from '../services/card.service';
import {MerchantService} from '../services/merchant.service';
import {BankService} from '../services/bank.service';
import {SlugService} from '../services/slug.service';
import {catchError, switchMap} from 'rxjs/operators';
import {StoreKey, StoreService} from '../services/store.service';
import {Merchant} from '../interfaces/merchant.interface';

@Injectable({
  providedIn: 'root'
})
export class CardPageResolver implements Resolve<boolean> {
  constructor(
    private auth: AuthService,
    private cards: CardService,
    private merchants: MerchantService,
    private bank: BankService,
    private slugs: SlugService,
    private router: Router,
    private store: StoreService,
    // private global: GlobalService,
    // private sidebar: SidebarService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const { handle } = route.params;
    const lowercased = handle.toLowerCase();

    if(handle !== lowercased) {
      void this.router.navigate([`/${lowercased}`]);
      return of(null);
    }

    // return forkJoin([
    //   this.auth.me().pipe(catchError(() => of(null))),
    //   this.merchants.getMerchant().pipe(catchError(() => of(null))),
    //   this.merchants.getBalance().pipe(catchError(() => of(null))),
    //   this.merchants.getTransactionHistory().pipe(catchError(() => of(null))),
    //   this.merchants.getRequirements().pipe(catchError(() => of(null))),
    //   this.cards.getCards().pipe(catchError(() => of(null))),
    //   this.slugs.getSlugs().pipe(catchError(() => of(null))),
    //   this.bank.getBankAccount().pipe(catchError(() => of(null))),
    // ]).pipe(catchError(() => of(null)));

    return this.merchants.getRoles().pipe(
      switchMap(() => {
        // Not really sure about this, but if merchant ID doesn't exist,
        // you should probably stay on register.
        if(!this.store.value<string>(StoreKey.MerchantId)) {
          this.router.navigate(['/register']);
          return of(null);
        }

        // Try to get the actual merchant with merchant ID from store.
        return this.merchants.getMerchant().pipe(
          switchMap((merchant: Merchant) => {
            // If there is no first name set, assume not updates.
            // TODO: Check if this is enough.
            console.log('merchant', merchant);
            if(!merchant?.first_name) {
              this.router.navigate(['/register']);
              return of(null);
            }

            return this.cards.getCard();
          }),
          catchError(() => of(null)),
        );
      }),
      catchError(() => of(null)),
    );

    // return forkJoin([
    //   this.auth.me().pipe(catchError(err => this.handleError(err, '/', 'auth'))),
    //   this.merchants.getRoles().pipe(catchError(err => this.handleError(err, '/', 'roles'))),
    //   this.merchants.getMerchant().pipe(tap(merchant => {
    //     if(merchant) {
    //       return;
    //     }
    //
    //     this.router.navigate(['/register']);
    //   }), catchError(err => this.handleError(err, '/register', 'merchant'))),
    // ]).pipe(catchError((err) => this.handleError(err, '/', 'global')));
  }

  handleError(err: any, redirect: string, provider?: string): Observable<null> {
    console.log('Dashboard resolver error:', provider || '', err);

    if(redirect) {
      this.router.navigate([redirect]);
    }

    return of(null);
  }
}
