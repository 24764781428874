<div class="create-flow-modal" *ngIf="opened">
  <div class="create-flow-modal__body">
    <div class="create-flow-modal__main">
      <!-- Step 3: Gallery -->
      <div class="create-flow-modal__content">
        <div class="create-flow-modal__screen create-flow-modal__gallery-screen">
          <div class="create-flow-modal__gallery-header">
            <h2 class="create-flow-modal__gallery-title" style="text-align: center;">Add Cover Art</h2>
          </div>
          <h4 class="create-flow-modal__gallery-subtitle">Cover Art</h4>
          <div *ngIf="!poster.url" ctoFilePicker [options]="filePickerOptions" class="create-flow-modal__cover-empty" (change)="onFileChange($event)">
            <p class="create-flow-modal__cover-empty-text">
              Select or upload a picture that shows what's in your video. A cover art stands out and draws viewers' attention.
            </p>
            <button type="button" class="create-flow-modal__cover-add-button">Upload Image</button>
          </div>
          <div *ngIf="poster.url" class="create-flow-modal__gallery-preview">
            <img [src]="poster.url" alt="" class="create-flow-modal__gallery-preview-image">
            <button (click)="deletePoster()" type="button" class="create-flow-modal__gallery-delete-button">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_128_751)">
                  <path d="M7.67228 2.87048H1.42228C1.19228 2.87048 1.00562 3.05715 1.00562 3.28715V4.95382C1.00562 5.18382 1.19228 5.37048 1.42228 5.37048H1.89312L3.95395 20.0113C3.98311 20.2171 4.15895 20.3705 4.36645 20.3705H15.9781C16.1856 20.3705 16.3614 20.2171 16.3906 20.0113L18.4514 5.37048H18.9223C19.1523 5.37048 19.3389 5.18382 19.3389 4.95382V3.28715C19.3389 3.05715 19.1523 2.87048 18.9223 2.87048H12.6723C12.6723 1.49215 11.5506 0.370483 10.1723 0.370483C8.79395 0.370483 7.67228 1.49215 7.67228 2.87048ZM15.6156 19.5371H4.72895L2.73478 5.37048H17.6089L15.6156 19.5371ZM18.5056 3.70382V4.53715H1.83895V3.70382H18.5056ZM10.1723 1.20382C11.0914 1.20382 11.8389 1.95132 11.8389 2.87048H8.50561C8.50561 1.95132 9.25311 1.20382 10.1723 1.20382Z" fill="#9C9C9C"/>
                  <path d="M12.2208 17.8688C12.2333 17.8697 12.245 17.8705 12.2558 17.8705C12.47 17.8705 12.6525 17.7063 12.6708 17.4888L13.5041 7.48884C13.5233 7.25967 13.3533 7.05717 13.1233 7.03884C12.8891 7.02384 12.6925 7.19051 12.6733 7.41884L11.84 17.4188C11.8216 17.648 11.9916 17.8505 12.2208 17.8688Z" fill="#9C9C9C"/>
                  <path d="M8.08904 17.8705C8.10071 17.8705 8.11238 17.8696 8.12404 17.8688C8.35321 17.8505 8.52321 17.648 8.50488 17.4188L7.67154 7.41882C7.65238 7.19048 7.45404 7.02298 7.22154 7.03882C6.99238 7.05715 6.82238 7.25965 6.84071 7.48882L7.67404 17.4888C7.69154 17.7063 7.87404 17.8705 8.08904 17.8705Z" fill="#9C9C9C"/>
                </g>
                <defs>
                  <clipPath id="clip0_128_751">
                    <rect width="20" height="20" fill="white" transform="translate(0.172363 0.370483)"/>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
        <div class="create-flow-modal__actions">
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button" (click)="cancel()">Cancel</button>
          </div>
          <div class="create-flow-modal__action-wrapper"></div>
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button new-form-button--primary" (click)="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="create-flow-modal__backdrop"></div>
</div>
