import {Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {FilePickerOptions} from '../interfaces/file-picker-options';

@Directive({
  selector: '[ctoFilePicker]'
})
export class FilePickerDirective implements OnDestroy {
  @Input() options: FilePickerOptions;
  @Output() change: EventEmitter<FileList | File[]> = new EventEmitter<FileList | File[]>();

  private input: HTMLInputElement;

  constructor() { }

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  @HostListener('click')
  onClick() {
    this.initFilePicker();
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();

    console.log(e);

    if(!this.options.drop) {
      return;
    }

    const { dataTransfer } = e;
    const regex = (this.options.drop as { match: RegExp })?.match || null;

    if (dataTransfer.items) {
      const files = [];

      for (let i = 0, length = dataTransfer.items.length; i < length; i++) {
        if(dataTransfer.items[i].kind !== 'file') {
          continue;
        }

        if(regex && !dataTransfer.items[i].type.match(regex)) {
          continue;
        }

        files.push(dataTransfer.items[i].getAsFile());
      }

      dataTransfer.items.clear();

      console.log(files);

      this.change.emit(files);

      return;
    } else {
      const files = dataTransfer.files;

      dataTransfer.clearData();

      this.change.emit(Array.from(files).filter(file => !(regex && !file.type.match(regex))));
    }
  }

  private initFilePicker() {
    this.input = document.createElement('input');

    this.input.type = 'file';

    if(this.options) {
      if(this.options.multiple) {
        this.input.multiple = this.options.multiple;
      }

      if(this.options.accept) {
        this.input.accept = this.options.accept;
      }
    }

    this.input.addEventListener('change', () => {
      this.change.emit(this.input.files);
    });

    this.input.click();
  }

  ngOnDestroy() {
    this.input?.remove();
  }
}
