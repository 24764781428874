import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
  ) { }

  getSingleUseToken(data: any): Observable<{ single_use_token: string; }> {
    return this.http.post<{ single_use_token: string; }>(`${environment.url.tapper}/v1/public/payment/guest_payment_intents`, data);
  }
}
