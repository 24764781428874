import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import {RouterModule} from '@angular/router';
import { UserFormComponent } from './pages/user-form/user-form.component';
import { CardEditModalComponent } from './components/card-edit-modal/card-edit-modal.component';
import { CardPageComponent } from './pages/card-page/card-page.component';
import { CardComponent } from './components/card/card.component';
import { PageWrapperComponent } from './pages/page-wrapper/page-wrapper.component';
import {CoreModule} from '../core/core.module';
import { AboutComponent } from './pages/about/about.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { CardShareComponent } from './components/card-share/card-share.component';
import { CropperComponent } from './components/cropper/cropper.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MediaCardPageComponent } from './pages/media-card-page/media-card-page.component';
import {SwiperModule} from 'swiper/angular';
import { SharedCardPageComponent } from './pages/shared-card-page/shared-card-page.component';
import { ArtventComponent } from './pages/artvent/artvent.component';
import { AdventComponent } from './components/advent/advent.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { CanonicalCardPageComponent } from './pages/canonical-card-page/canonical-card-page.component';
import { CanonicalCardComponent } from './components/canonical-card/canonical-card.component';
import { CanonicalCardPageWrapperComponent } from './pages/canonical-card-page-wrapper/canonical-card-page-wrapper.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditContributionsComponent } from './components/edit-contributions/edit-contributions.component';
import { EditGalleryComponent } from './components/edit-gallery/edit-gallery.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { EditVideoCoverComponent } from './components/edit-video-cover/edit-video-cover.component';
import { ContributionInputComponent } from './components/contribution-input/contribution-input.component';
import { MediaGalleryComponent } from './components/media-gallery/media-gallery.component';
import { VideoElementComponent } from './components/video-element/video-element.component';
import {CombinedModule} from './combined.module';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {ErrorAlertComponent} from './components/error-alert/error-alert.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    UserFormComponent,
    CardEditModalComponent,
    CardPageComponent,
    CardComponent,
    PageWrapperComponent,
    AboutComponent,
    NotFoundComponent,
    PreloaderComponent,
    CardShareComponent,
    CropperComponent,
    DateInputComponent,
    VideoPlayerComponent,
    FaqComponent,
    MediaCardPageComponent,
    SharedCardPageComponent,
    ArtventComponent,
    AdventComponent,
    SocialLinksComponent,
    CanonicalCardPageComponent,
    CanonicalCardComponent,
    CanonicalCardPageWrapperComponent,
    RedirectComponent,
    EditProfileComponent,
    EditContributionsComponent,
    EditGalleryComponent,
    UploaderComponent,
    EditVideoCoverComponent,
    ContributionInputComponent,
    MediaGalleryComponent,
    VideoElementComponent,
    NotificationsComponent,
    ErrorAlertComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SwiperModule,
    CombinedModule,
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PreloaderComponent,
    CropperComponent,
    UploaderComponent,
    NotificationsComponent,
    ErrorAlertComponent,
  ]
})
export class SharedModule { }
