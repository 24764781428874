import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  listener$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  refresh() {
    this.listener$.next(true);
  }
}
