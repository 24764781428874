import { Component, OnInit } from '@angular/core';
import {UploaderService} from './uploader.service';
import {BehaviorSubject} from 'rxjs';
import {first} from 'rxjs/operators';
import {CardService} from '../../../core/services/card.service';
import {FileService} from '../../../core/services/file.service';
import {GalleryService} from '../../../core/services/gallery.service';
import {RefreshService} from '../../../core/services/refresh.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';

interface UploaderMessage {
  message: string;
  progress: number;
}

@Component({
  selector: 'cto-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  events: any[] | null = [];
  order: any[] = [];
  count$ = new BehaviorSubject<number>(0);
  ignore = true;
  done = false;

  constructor(
    private service: UploaderService,
    private card: CardService,
    private file: FileService,
    private gallery: GalleryService,
    private refresh: RefreshService,
  ) { }

  ngOnInit(): void {
    // TODO: Refactor.
    this.count$.subscribe(count => {
      if(this.ignore || count > 0) {
        return;
      }

      this.gallery.updateGalleryOrder(this.order).pipe(first()).subscribe(res => {
        console.log('updated card from uploader', res);

        setTimeout(() => {
          this.refresh.refresh();
        }, 1000);
      });

      this.done = true;
      this.ignore = true;
    });

    this.service.events$.subscribe(events => {
      if(!events) {
        this.reset();
        return;
      }

      if(this.done) {
        this.reset();
      }

      // Reorder only.
      if(!events.upload.length && !events.delete.length && !events.poster.length && !events.crop.length) {
        this.gallery.updateGalleryOrder(events.order).pipe(first()).subscribe(res => {
          setTimeout(() => {
            this.refresh.refresh();
          }, 1000);
        });

        this.done = true;

        return;
      }

      this.count$.next(events.upload.length + events.delete.length + events.poster.length + events.crop.length);
      this.ignore = false;

      this.order = events.order;

      this.prepareDelete(events.delete);
      this.preparePoster(events.poster);
      this.prepareUpload(events.upload);
      this.prepareCrop(events.crop);
    });
  }

  preparePoster(events: any[]) {
    if(!events?.length) {
      return;
    }

    for(const [index, item] of events.entries()) {
      const event = {
        type: 'image',
        subject: new BehaviorSubject<UploaderMessage>({ message: `Uploading ${index + 1} of ${events.length}...`, progress: 0}),
      }

      this.events.push(event);

      void this.initUploadPoster(item, event.subject);
    }
  }

  prepareUpload(events: any[]) {
    if(!events?.length) {
      return;
    }

    for(const [index, item] of events.entries()) {
      const event = {
        type: item.type,
        subject: new BehaviorSubject<UploaderMessage>({ message: `Uploading ${index + 1} of ${events.length}...`, progress: 0 }),
      }

      this.events.push(event);

      switch(item.type) {
        case 'image': {
          void this.initUploadImage(item, event.subject);
          break;
        }

        case 'video': {
          void this.initUploadVideo(item, event.subject);
          break;
        }
      }
    }
  }

  prepareCrop(events: any[]) {
    if(!events?.length) {
      return;
    }

    for(const [index, item] of events.entries()) {
      const event = {
        type: item.type,
        subject: new BehaviorSubject<UploaderMessage>({ message: `Updating ${index + 1} of ${events.length}...`, progress: 0 }),
      }

      this.events.push(event);

      void this.initCropImage(item, event.subject);
    }
  }

  async initCropImage(item: any, subject: BehaviorSubject<UploaderMessage>) {
    const { top, bottom, left, right } = item.raw;

    this.gallery.cropImage(item.id, { top, bottom, left, right }).pipe(first()).subscribe(() => {
      subject.next({ message: 'Updated.', progress: 100 });
      this.count$.next(this.count$.value - 1);
    });
  }

  async initUploadPoster(item: any, subject: BehaviorSubject<UploaderMessage>) {
    const upload_data = await this.file.uploadImage(item.poster.file);

    if(!upload_data?.url) {
      subject.next({ message: '', progress: 0 });
      this.count$.next(this.count$.value - 1);
      return;
    }

    this.gallery.updateVideoPoster(item.id, upload_data.url).pipe(first()).subscribe(() => {
      subject.next({ message: 'Uploaded.', progress: 100 });
      this.count$.next(this.count$.value - 1);
    });
  }

  async initUploadImage(data: any, subject: BehaviorSubject<UploaderMessage>) {
    const { id, progress } = (await this.file.observeUploadImage(data.file)) || {};

    if(!progress) {
      subject.next({ message: '', progress: 0 });
      this.count$.next(this.count$.value - 1);
      return;
    }

    progress.subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent: {
          break;
        }

        case HttpEventType.ResponseHeader: {
          break;
        }

        case HttpEventType.UploadProgress: {
          const progress = Math.round(event.loaded / event.total * 100);
          subject.next({ message: subject.value.message, progress: progress > 99 ? 99 : progress });
          break;
        }

        case HttpEventType.Response: {
          const { result: { variants } } = event.body;

          const url = variants.find(item => item.includes('/original')) || '';

          this.gallery.addImage(url).pipe(first()).subscribe(({ id }) => {
            const index = this.order.indexOf(data.fileId);

            const { top, bottom, left, right } = data.raw;

            this.gallery.cropImage(id, { top, bottom, left, right }).pipe(first()).subscribe(() => {
              this.order.splice(index, 1, id);

              subject.next({ message: 'Uploaded.', progress: 100 });
              this.count$.next(this.count$.value - 1);
            });
          });
        }
      }
    });
  }

  async initUploadVideo(data: any, subject: BehaviorSubject<UploaderMessage>) {
    const { id, progress } = (await this.file.observeUploadVideo(data.file)) || {};

    if(!progress) {
      subject.next({ message: '', progress: 0 });
      this.count$.next(this.count$.value - 1);
      return;
    }

    progress.subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent: {
          break;
        }

        case HttpEventType.ResponseHeader: {
          break;
        }

        case HttpEventType.UploadProgress: {
          const progress = Math.round(event.loaded / event.total * 100);
          subject.next({ message: subject.value.message, progress: progress > 99 ? 99 : progress });
          break;
        }

        case HttpEventType.Response: {
          this.gallery.addVideo(id).pipe(first()).subscribe( async () => {
            if(data.poster?.file) {
              const upload_data = await this.file.uploadImage(data.poster.file);

              await this.gallery.updateVideoPoster(id, upload_data.url).toPromise();
            }

            const index = this.order.indexOf(data.fileId);

            this.order.splice(index, 1, id);

            subject.next({ message: 'Uploaded.', progress: 100 });
            this.count$.next(this.count$.value - 1);
          })
        }
      }
    });

    this.file.uploadVideo(data.file)
      .then(res => {

      });
  }

  prepareDelete(events: any[]) {
    if(!events?.length) {
      return;
    }

    for(const [index, item] of events.entries()) {
      const event = {
        type: 'delete',
        subject: new BehaviorSubject<UploaderMessage>({ message: `Deleting ${index + 1} of ${events.length}...`, progress: 0 }),
      }

      this.events.push(event);

      switch(item.type) {
        case 'image': {
          void this.initDeleteImage(item, event.subject);
          break;
        }

        case 'video': {
          void this.initDeleteVideo(item, event.subject);
          break;
        }
      }
    }
  }

  initDeleteImage(data: any, subject: BehaviorSubject<UploaderMessage>) {
    this.gallery.deleteImage(data.id).pipe(first())
      .subscribe(() => {
        const index = this.order.indexOf(data.id);
        this.order.splice(index, 1);

        subject.next({ message: 'Deleted.', progress: 100 });
        this.count$.next(this.count$.value - 1);
      }, () => {
        subject.next({ message: '', progress: 0 });
        this.count$.next(this.count$.value - 1);
      });
  }

  initDeleteVideo(data: any, subject: BehaviorSubject<UploaderMessage>) {
    this.gallery.deleteVideo(data.id).pipe(first())
      .subscribe(() => {
        const index = this.order.indexOf(data.id);
        this.order.splice(index, 1);

        subject.next({ message: 'Deleted.', progress: 100 });
        this.count$.next(this.count$.value - 1);
      }, () => {
        subject.next({ message: '', progress: 0 });
        this.count$.next(this.count$.value - 1);
      });
  }

  initReorder() {
    // TODO: Handle reorder logic.
  }

  reset() {
    this.events = [];
    this.order = [];
    this.count$.next(0);
    this.ignore = true;
    this.done = false;
  }

  close() {
    this.service.close();
  }

  clickOutside() {
    if(this.count$.value > 0) {
      return;
    }

    this.close();
  }
}
