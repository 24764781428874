import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  events$ = new BehaviorSubject<any | null>(null);

  constructor(private http: HttpClient) {
  }

  init(events: any) {
    this.events$.next(events);
  }

  close() {
    this.events$.next(null);
  }
}
