import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FilePickerOptions} from '../../../core/interfaces/file-picker-options';
import {HeicFileConverterService} from '../../../core/services/heic-file-converter.service';
import {FileService} from '../../../core/services/file.service';
import {CardService} from '../../../core/services/card.service';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {ImageCropperService} from '../image-cropper/image-cropper.service';
import {UploaderService} from '../uploader/uploader.service';
import {VideoCoverService} from '../../../core/services/video-cover.service';
import {Card} from '../../../core/interfaces/card.interface';
import {GalleryImageItem, GalleryItem} from '../../../core/interfaces/gallery.interface';
import {CroppedImageData} from '../image-cropper/image-cropper.component';
import {Options} from 'sortablejs';
import {NotificationService} from '../../../core/services/notification.service';

const uid = () => 'file.' + Date.now();

@Component({
  selector: 'cto-edit-gallery-screen',
  templateUrl: './edit-gallery-screen.component.html',
  styleUrls: ['./edit-gallery-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditGalleryScreenComponent implements OnInit {
  @Output() loading = new EventEmitter<boolean>();
  @Output() success = new EventEmitter<boolean>();

  @ViewChild('sortableRef', { static: false }) sortableRef: ElementRef;

  dragOver = false;

  opened = false;

  gallery: any[] = [];

  galleryLimit = 9;

  selectedIndex = -1;

  deleted: any[] = [];

  sortable: any;

  order = [];

  imageSizeLimitMB = 10;
  imageSizeLimit = this.imageSizeLimitMB * 1024 * 1024; // in B

  filePickerOptions: FilePickerOptions = {
    accept: 'video/*,.png,.gif,.jpg,.jpeg,.webp,.heic,.heif',
    multiple: true,
    drop: {
      match: /png|gif|jpeg|jpg|webp|video|heic|heif/gi,
    }
  };

  sortableOptions: Options = {
    onUpdate: ({ oldIndex, newIndex }) => {
      console.log(oldIndex, newIndex);

      this.selectedIndex = newIndex;

      this.reorder(oldIndex, newIndex);
    },
    filter: '.not-draggable',
  };

  constructor(
    private heic: HeicFileConverterService,
    private file: FileService,
    private card: CardService,
    private store: StoreService,
    private cropper: ImageCropperService,
    private uploader: UploaderService,
    private cover: VideoCoverService,
    private notifications: NotificationService,
  ) { }

  async ngOnInit() {
    this.uploader.close();

    this.order = this.store.value<Card>(StoreKey.Card)?.gallery_order || [];

    await this.initLibrary();
  }

  imageStyle(image) {
    return {
      width: image.width || '100%',
      height: image.height || '100%',
      top: image.top || '0',
      left: image.left || '0',
    }
  }

  async initLibrary() {
    return new Promise(resolve => {
      const gallery = this.store.value<GalleryItem[]>(StoreKey.Gallery);

      if(!gallery?.length) {
        resolve(false);
        return;
      }

      this.gallery = gallery.map(element => ({
        ...element.item,
        old: true,
        type: element.type,
      }));

      this.selectedIndex = 0;

      resolve(true);
    });
  }

  async onFileChange(list: FileList | File[]) {
    if(!list?.length) {
      return;
    }

    this.toggleDragOver();

    const files = Array.from(list).slice(0, this.galleryLimit - this.gallery.length);

    this.initAddFlow(Array.from(files));
  }

  validateImage(file: File) {
    const errors = [];

    if(file.size > this.imageSizeLimit) {
      errors.push(`Maximum image size is ${this.imageSizeLimitMB}MB.`);
    }

    this.notifications.append(errors);

    console.log(errors);

    return !errors.length;
  }

  async initAddFlow(files: File[]) {
    for(let file of files) {
      if(file.type.includes('image') || file.type.includes('hei')) {
        await this.initImageFlow(file, files.length > 1);
        continue;
      }

      if(file.type.includes('video')) {
        await this.initVideoFlow(file);
        continue;
      }

      console.log('Unsupported file type:', file.type);
    }
  }

  async initImageFlow(file: File, silent = false) {
    if(!this.validateImage(file)) {
      return;
    }

    const converted = await this.heic.convert(file);

    return new Promise(resolve => {
      this.cropper.open({
        src: converted,
        silent,
      }).subscribe((data: CroppedImageData) => {
        if(!data) {
          resolve(false);
          return;
        }

        const fileId = uid();

        console.log(data);

        this.gallery.push({
          type: 'image',
          fileId,
          file: converted,
          ...data,
        });

        this.order.push(fileId);

        if(this.selectedIndex < 0) {
          this.selectedIndex = 0;
        }

        resolve(true);
      });
    });
  }

  async initVideoFlow(file: File) {
    const fileId = uid();

    this.gallery.push({
      type: 'video',
      fileId,
      file,
    });

    this.order.push(fileId);

    if(this.selectedIndex < 0) {
      this.selectedIndex = 0;
    }
  }

  previewStyle(index: number) {
    const image = this.gallery[index];
  }

  select(index: number) {
    this.selectedIndex = index;
  }

  isSelected(index: number) {
    return this.selectedIndex === index;
  }

  prepareUpload() {
    return this.gallery.filter(item => !!item.file);
  }

  preparePoster() {
    return this.gallery.filter(item => !item.file && item.poster?.file);
  }

  prepareCrop() {
    return this.gallery.filter(item => item.type === 'image' && !item.file && !!item.raw);
  }

  prepareDelete() {
    return this.deleted;
  }

  moveLeft() {
    if(this.selectedIndex < 1) {
      return;
    }

    this.move(this.selectedIndex, this.selectedIndex - 1);
  }

  moveRight() {
    if(this.selectedIndex >= this.gallery.length - 1) {
      return;
    }

    this.move(this.selectedIndex, this.selectedIndex + 1);
  }

  move(from: number, to: number) {
    const item = this.gallery[from];
    this.gallery.splice(from, 1);
    this.gallery.splice(to, 0, item);

    const id = this.order[from];
    this.order.splice(from, 1);
    this.order.splice(to, 0, id);
  }

  save() {
    this.uploader.init({
      order: this.order,
      poster: this.preparePoster(),
      upload: this.prepareUpload(),
      delete: this.prepareDelete(),
      crop: this.prepareCrop(),
    });

    // this.file.uploadVideo(this.gallery.pop()?.file)
    //   .then(res => {
    //     console.log('vide upload res', res);
    //     this.gs.addVideo(res.id).pipe(first()).subscribe(r => {
    //       console.log('added video to card', r);
    //     })
    //   });

    this.success.emit(true);
  }

  deleteItem(index: number) {
    if(!window.confirm('Are you sure you want to delete this item? This action is irreversible!')) {
      return;
    }

    const [ item ] = this.gallery.splice(index, 1);

    if(item.id) {
      this.deleted = [...this.deleted, item];
    }

    this.selectedIndex = Math.max(0, index - 1);

    if(!this.gallery.length) {
      this.selectedIndex = -1;
    }
  }

  addPosterImage(index: number) {
    this.cover.open(this.gallery[index]).subscribe(data => {
      if(!data) {
        return;
      }

      this.gallery[index].poster = data;
    });
  }

  reorder(from: number, to: number) {
    // this.gallery.splice(to, 0, this.gallery.splice(from, 1)[0]);
    this.order.splice(to, 0, this.order.splice(from, 1)[0]);
  }

  cropImage(index: number) {
    const item = this.gallery[index] as GalleryImageItem;

    console.log(item);

    this.cropper.open({
      src: item.url,
      position: item.crop_data || null,
    }).subscribe((data: CroppedImageData) => {
      if(!data) {
        return;
      }

      this.gallery[index] = {
        ...item,
        ...data,
      }
    });
  }

  toggleDragOver(e?: DragEvent) {
    e?.preventDefault();

    this.dragOver = !this.dragOver;
  }
}
