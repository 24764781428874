<footer class="section">
  <div class="container">
    <div class="cta-links">
      <img src="/assets/images/cto-logo-heart-dark.svg" alt="Contribute.to heart logo" class="cta-links__image">
      <div class="cta-links__content-wrapper">
        <div class="cta-links__content">
          <h3 class="cta-links__title">
            The easiest way to collect contributions
          </h3>
          <a class="button button--secondary button--large cta-links__button" (click)="auth.signup()">Get Started</a>
        </div>
        <div class="cta-links__links-wrapper">
          <ul class="cta-links__links">
            <!--              <li class="cta-links__link-item"><a href="#" class="cta-links__link">About</a></li>-->
            <!--              <li class="cta-links__link-item"><a href="#" class="cta-links__link">FAQ</a></li>-->
            <!--              <li class="cta-links__link-item"><a href="#" class="cta-links__link">Contact Us</a></li>-->
            <li class="cta-links__link-item"><a routerLink="/about" class="cta-links__link">About Us</a></li>
            <li class="cta-links__link-item"><a routerLink="/faq" class="cta-links__link">FAQ</a></li>
            <li class="cta-links__link-item"><a href="https://supertab.co/privacy-policy/" target="_blank" class="cta-links__link">Privacy Policy</a></li>
            <li class="cta-links__link-item"><a href="https://supertab.co/terms-of-service/" target="_blank" class="cta-links__link">Terms of Service</a></li>
          </ul>
        </div>
      </div>
      <div class="cta-links__meta">
        <cto-social-links [links]="links"></cto-social-links>
        ©{{ year }} Contribute.to
      </div>
    </div>
  </div>
</footer>
