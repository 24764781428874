import { NgModule } from '@angular/core';
import {EditSlugScreenComponent} from './components/edit-slug-screen/edit-slug-screen.component';
import {EditProfileScreenComponent} from './components/edit-profile-screen/edit-profile-screen.component';
import {EditGalleryScreenComponent} from './components/edit-gallery-screen/edit-gallery-screen.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {ImageCropperComponent} from './components/image-cropper/image-cropper.component';
import {SortablejsModule} from 'ngx-sortablejs';

@NgModule({
  declarations: [
    EditSlugScreenComponent,
    EditProfileScreenComponent,
    EditGalleryScreenComponent,
    ImageCropperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SortablejsModule,
  ],
  exports: [
    EditSlugScreenComponent,
    EditProfileScreenComponent,
    EditGalleryScreenComponent,
    ImageCropperComponent,
  ]
})
export class CombinedModule { }
