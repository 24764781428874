import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnloadService {

  constructor() { }

  bind(message: string, callback: () => void = () => {}) {
    window.onbeforeunload = e => {
      (e || window.event).returnValue = message;

      callback();

      return message;
    };
  }

  unbind() {
    window.onbeforeunload = null;
  }
}
