import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ImageUploadData, ImageUploadResponse} from '../interfaces/image.interface';
import {environment} from '../../../environments/environment';
import {Merchant} from '../interfaces/merchant';
import {StoreKey, StoreService} from './store.service';
import {Card, CardResponse} from '../interfaces/card.interface';
import {first, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FileService} from './file.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  constructor(
    private http: HttpClient,
    private store: StoreService,
    private file: FileService,
  ) { }

  addImage(image_url: string): Observable<any> {
    return this.http.post<ImageUploadData>(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}/gallery/images`, {
      image_url,
      merchant_id: this.store.value<Merchant>(StoreKey.Merchant)?.id,
    });
  }

  addVideo(video_id: string): Observable<any> {
    return this.http.post<any>(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}/gallery/videos`, {
      video_id,
    });
  }

  deleteImage(image_id: string): Observable<any> {
    return this.http.delete(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}/gallery/images/${image_id}`);
  }

  deleteVideo(video_id: string) {
    return this.http.delete(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}/gallery/videos/${video_id}`);
  }

  updateVideoPoster(video_id: string, image_url: string) {
    return this.http.patch<any>(`${environment.url.tapper}/v1/videos/${video_id}/image`, {
      image_url,
    });
  }

  cropImage(image_id: string, crop_data: any) {
    return this.http.patch(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}/gallery/images/${image_id}`, crop_data);
  }

  updateGalleryOrder(gallery_order: string[]) {
    return this.http.patch<CardResponse>(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}`, {
      gallery_order,
    });
  }
}
