import {Component, OnInit, ViewChild} from '@angular/core';
import {EditGalleryService} from './edit-gallery.service';
import {EditGalleryScreenComponent} from '../edit-gallery-screen/edit-gallery-screen.component';

@Component({
  selector: 'cto-edit-gallery',
  templateUrl: './edit-gallery.component.html',
  styleUrls: ['./edit-gallery.component.scss']
})
export class EditGalleryComponent implements OnInit {
  @ViewChild(EditGalleryScreenComponent) gallery: EditGalleryScreenComponent;

  opened = false;

  constructor(
    private service: EditGalleryService,
  ) { }

  ngOnInit(): void {
    this.service.opened.subscribe(async opened => {
      this.opened = opened;
    });
  }

  save() {
    this.gallery?.save();
    this.service.close();
  }

  gallerySuccess() {
    this.close();
  }

  close() {
    this.service.close();
  }

  cancel() {
    this.close();
  }
}
