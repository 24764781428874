import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ImageCropperOptions, ImageCropperService} from './image-cropper.service';
import {ReplaySubject} from 'rxjs';

interface RawImageData {
  url: string;
  width: number;
  height: number;
}

export interface CroppedImageData {
  url: string;
  scaled: {
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  },
  raw: {
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  }
}

enum ImageRatioType {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square',
}

const FRAME_WIDTH = 400;
const FRAME_HEIGHT = 500;
const WIDTH_HEIGHT_RATIO = FRAME_WIDTH / FRAME_HEIGHT;

@Component({
  selector: 'cto-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  /**
   * Image element reference.
   */
  @ViewChild('image', { static: false }) imageRef: ElementRef;

  /**
   * Determines the state of the cropper.
   */
  opened = false;

  /**
   * Uploaded image ratio.
   */
  ratio = 1;

  /**
   * Ratio between preview and the original image.
   */
  scale = 1;

  /**
   * Uploaded image ratio type.
   */
  ratioType = ImageRatioType.Square;

  /**
   * Top position of the preview.
   */
  top = 0;

  /**
   * Top position of the preview.
   */
  left = 0;

  /**
   * Cached top position while moving.
   */
  fromTop = 0;

  /**
   * Cached left position while moving.
   */
  fromLeft = 0;

  /**
   * Top applied as a style to the preview.
   */
  styleTop = '0px';

  /**
   * Left applied as a style to the preview.
   */
  styleLeft = '0px';

  /**
   * Raw width of the uploaded image.
   */
  rawWidth = 0;

  /**
   * Raw height of the uploaded image.
   */
  rawHeight = 0;

  /**
   * Calculated width of the (preview of) uploaded image (at 100%).
   */
  previewWidth = 0;

  /**
   * Calculated height of the (preview of) uploaded image (at 100%).
   */
  previewHeight = 0;

  /**
   * Actual shown width.
   */
  zoomWidth = 0;

  /**
   * Actual shown height.
   */
  zoomHeight = 0;

  /**
   * Width applied as a style to the preview.
   */
  styleWidth = '0';

  /**
   * Height applied as a style to the preview.
   */
  styleHeight = '0';

  /**
   * Overflow of the uploaded image on the X axis.
   */
  overflowX = 0;

  /**
   * Overflow of the uploaded image on the Y axis.
   */
  overflowY = 0;

  /**
   * Flag to check if user has its mouse down on the image.
   */
  dragging = false;

  /**
   * X coordinate of the point where user initially clicked.
   */
  startX = -1;

  /**
   * Y coordinate of the point where user initially clicked.
   */
  startY = -1;

  /**
   * Zoom value of the current preview.
   */
  zoom = 100;

  subject$: ReplaySubject<CroppedImageData>;

  constructor(
    private service: ImageCropperService,
  ) { }

  ngOnInit(): void {
    this.service.options$.subscribe(async (data) => {
      if(!data?.options) {
        this.reset();
        this.opened = false;

        return;
      }

      this.subject$ = data.subject$;

      await this.load(data.options);
    });
  }

  setTop(top: number) {
    this.top = top;
    this.styleTop = this.top + 'px';
  }

  setLeft(left: number) {
    this.left = left;
    this.styleLeft = this.left + 'px';
  }

  async load(options: ImageCropperOptions) {
    const raw = await this.srcToImage(options.src);
    const { position, silent } = options;

    this.reset();

    this.imageRef.nativeElement.src = raw.url;

    this.initCropper(raw, position, silent);
  }

  initCropper(raw: RawImageData, position: any = {}, silent = false) {
    this.rawWidth = raw.width;
    this.rawHeight = raw.height;

    this.ratio = this.rawWidth / this.rawHeight;

    this.overflowY = 0;
    this.overflowX = 0;

    this.setTop(0);
    this.setLeft(0);

    switch (true) {
      case this.ratio > WIDTH_HEIGHT_RATIO: {
        this.ratioType = ImageRatioType.Landscape;

        this.previewWidth = Math.floor((FRAME_HEIGHT / this.rawHeight) * this.rawWidth);
        this.previewHeight = FRAME_HEIGHT;

        this.overflowX = this.previewWidth - FRAME_WIDTH;
        this.scale = Math.round(this.rawHeight / FRAME_HEIGHT * 100) / 100;

        this.setLeft(-1 * this.overflowX / 2);

        break;
      }

      case this.ratio < WIDTH_HEIGHT_RATIO: {
        this.ratioType = ImageRatioType.Portrait;

        this.previewWidth = FRAME_WIDTH;
        this.previewHeight = Math.floor((FRAME_WIDTH / this.rawWidth) * this.rawHeight);

        this.overflowY = this.previewHeight - FRAME_HEIGHT;
        this.scale = Math.round(this.rawWidth / FRAME_WIDTH * 100) / 100;

        this.setTop(-1 * this.overflowY / 2);

        break;
      }

      default: {
        this.ratioType = ImageRatioType.Square;
        this.previewWidth = FRAME_WIDTH;
        this.previewHeight = FRAME_HEIGHT;
      }
    }

    this.zoomWidth = this.previewWidth;
    this.zoomHeight = this.previewHeight;

    this.styleWidth = this.zoomWidth + 'px';
    this.styleHeight = this.zoomHeight + 'px';

    const { top, left } = position ?? {};

    if(top || left) {
      this.dataToCropperPosition({ top, left });
    }

    if(!silent) {
      this.opened = true;
      return;
    }

    this.save();
  }

  srcToImage(src: File | string): Promise<RawImageData> {
    return new Promise(resolve => {
      const image = new Image();

      if(typeof src === 'string') {
        image.src = src;
        image.onload = () => resolve({ url: image.src, width: image.naturalWidth, height: image.naturalHeight });

        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        image.src = reader.result as string;
        image.onload = () => resolve({ url: image.src, width: image.naturalWidth, height: image.naturalHeight });
      }

      reader.readAsDataURL(src);
    });
  }

  onDragStart(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onMouseDown(e) {
    e.preventDefault();

    this.dragging = true;
    this.startX = e.clientX;
    this.startY = e.clientY;
    this.fromTop = this.top;
    this.fromLeft = this.left;
  }

  onMouseMove(e) {
    e.preventDefault();
    e.stopPropagation();

    if(!this.dragging) {
      return;
    }

    this.onMove({
      deltaX: e.clientX - this.startX,
      deltaY: e.clientY - this.startY,
    });
  }

  onMouseUp(e) {
    e.preventDefault();

    this.reset();
  }

  onTouchStart(e) {
    e.preventDefault();

    this.dragging = true;
    this.startX = e.changedTouches[0].clientX;
    this.startY = e.changedTouches[0].clientY;
    this.fromTop = this.top;
    this.fromLeft = this.left;
  }

  onTouchMove(e) {
    e.preventDefault();

    if(!this.dragging) {
      return;
    }

    this.onMove({
      deltaX: e.changedTouches[0].clientX - this.startX,
      deltaY: e.changedTouches[0].clientY - this.startY,
    });
  }

  onTouchEnd(e) {
    e.preventDefault();

    this.reset();
  }

  reset() {
    this.dragging = false;
    this.startX = -1;
    this.startY = -1;
    this.fromTop = 0;
    this.fromLeft = 0;
    this.zoom = 100;
  }

  onMove(data) {
    // deltaX < 0 -> LEFT
    // deltaY < 0 -> UP
    const { deltaX, deltaY } = data;

    if(this.zoom === 1) {
      switch (this.ratioType) {
        case ImageRatioType.Landscape: {
          const left = this.fromLeft + deltaX;

          switch(true) {
            case left > 0: {
              this.left = 0;
              break;
            }

            case left < -1 * this.overflowX: {
              this.left = -1 * this.overflowX;
              break;
            }

            default: {
              this.left = left;
            }
          }

          this.styleLeft = this.left + 'px';

          break;
        }

        case ImageRatioType.Portrait: {
          const top = this.fromTop + deltaY;

          switch(true) {
            case top > 0: {
              this.top = 0;
              break;
            }

            case top < -1 * this.overflowY: {
              this.top = -1 * this.overflowY;
              break;
            }

            default: {
              this.top = top;
            }
          }

          this.styleTop = this.top + 'px';

          break;
        }

        default: {}
      }
      return;
    }

    const left = this.fromLeft + deltaX;
    const top = this.fromTop + deltaY;

    switch(true) {
      case left > 0: {
        this.left = 0;
        break;
      }

      case left < -1 * this.overflowX: {
        this.left = -1 * this.overflowX;
        break;
      }

      default: {
        this.left = left;
      }
    }

    switch(true) {
      case top > 0: {
        this.top = 0;
        break;
      }

      case top < -1 * this.overflowY: {
        this.top = -1 * this.overflowY;
        break;
      }

      default: {
        this.top = top;
      }
    }

    this.styleLeft = this.left + 'px';
    this.styleTop = this.top + 'px';
  }

  zoomIn() {
    if(this.zoom >= 200) {
      return;
    }

    this.zoom += 10;

    this.onZoomIn();
  }

  zoomOut() {
    if(this.zoom <= 100) {
      return;
    }

    this.zoom -= 10;

    this.onZoomOut();
  }

  onZoomIn() {
    const w = this.previewWidth * (this.zoom / 100);
    const h = this.previewHeight * (this.zoom / 100);

    const diffW = w - this.zoomWidth;
    const diffH = h - this.zoomHeight;

    this.zoomWidth = w;
    this.zoomHeight = h;

    this.styleWidth = w + 'px';
    this.styleHeight = h + 'px';

    this.setTop(this.top - (diffH / 2));
    this.setLeft(this.left - (diffW / 2));

    this.overflowX += diffW;
    this.overflowY += diffH;
  }

  onZoomOut() {
    const w = this.previewWidth * (this.zoom / 100);
    const h = this.previewHeight * (this.zoom / 100);

    const diffW = this.zoomWidth - w;
    const diffH = this.zoomHeight - h;

    this.zoomWidth = w;
    this.zoomHeight = h;

    this.styleWidth = w + 'px';
    this.styleHeight = h + 'px';

    const newTop = this.top + (diffH / 2);
    const newLeft = this.left + (diffW / 2);

    this.overflowX -= diffW;
    this.overflowY -= diffH;

    // this.setTop(newTop);
    // this.setLeft(newLeft);

    switch(true) {
      case newTop > 0: {
        this.setTop(0);
        break;
      }

      case newTop < -1 * this.overflowY: {
        this.setTop(-1 * this.overflowY);
        break;
      }

      default: {
        this.setTop(newTop);
      }
    }

    switch(true) {
      case newLeft > 0: {
        this.setLeft(0);
        break;
      }

      case newLeft < -1 * this.overflowX: {
        this.setLeft(-1 * this.overflowX);
        break;
      }

      default: {
        this.setLeft(newLeft);
      }
    }
  }

  ensureNonNegative(value: number): number {
    return Math.max(0, value);
  }

  dataToCropperPosition({ top, left }) {
    this.setTop(top ? -1 * top : 0);
    this.setLeft(left ? -1 * left : 0);
  }

  getCropData(): CroppedImageData {
    const data: any = {
      url: this.imageRef.nativeElement.src,
      scaled: {},
      raw: {},
      top: '',
      left: '',
      width: '',
      height: '',
    };

    console.log('zw, zh', this.zoomWidth, this.zoomHeight);
    console.log('pw, ph', this.previewWidth, this.previewHeight);
    console.log('rw, rh', this.rawWidth, this.rawHeight);

    const scale = this.rawWidth / this.zoomWidth

    data.width = this.styleWidth;
    data.height = this.styleHeight;
    data.top = this.styleTop;
    data.left = this.styleLeft;

    data.scaled.width = Math.round(this.zoomWidth);
    data.scaled.height = Math.round(this.zoomHeight);

    data.scaled.top = this.ensureNonNegative(Math.round(this.top ? -1 * this.top : 0));
    data.scaled.bottom = this.ensureNonNegative(Math.round(data.scaled.height - data.scaled.top - FRAME_HEIGHT));
    data.scaled.left = this.ensureNonNegative(Math.round(this.left ? -1 * this.left : 0));
    data.scaled.right = this.ensureNonNegative(Math.round(data.scaled.width - data.scaled.left - FRAME_WIDTH));

    data.raw.width = this.ensureNonNegative(Math.round(this.rawWidth));
    data.raw.height = this.ensureNonNegative(Math.round(this.rawHeight));

    data.raw.top = this.ensureNonNegative(Math.round(data.scaled.top * scale));
    data.raw.bottom = this.ensureNonNegative(Math.round(data.scaled.bottom * scale));
    data.raw.left = this.ensureNonNegative(Math.round(data.scaled.left * scale));
    data.raw.right = this.ensureNonNegative(Math.round(data.scaled.right * scale));

    console.log(data);

    return data as CroppedImageData;
  }

  cancel() {
    this.subject$.next(null);
  }

  save() {
    const data = this.getCropData();

    this.subject$.next(data);
  }
}
