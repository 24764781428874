import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MediaGalleryService} from '../../../core/services/media-gallery.service';
import {GalleryImageItem, GalleryItem} from '../../../core/interfaces/gallery.interface';
import {GalleryVideoItem} from '../../../core/interfaces/gallery.interface/gallery-video-item.interface';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';

@Component({
  selector: 'cto-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {
  @Input() gallery: GalleryItem[] = [];

  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
  };

  state = {
    opened: false,
    index: -1,
  }

  constructor(
    private service: MediaGalleryService,
  ) { }

  ngOnInit(): void {
    this.service.opened.subscribe(index => {
      this.state.index = index;
      this.state.opened = this.state.index !== -1;

      if(!this.state.opened) {
        return;
      }

      this.swiper?.swiperRef?.slideToLoop(this.state.index, 0);
    });
  }

  getSingleImageSource() {
    if(!this.gallery.length) {
      return 'assets/images/hero-default.png';
    }

    if(this.gallery[0].type === 'video') {
      return (this.gallery[0].item as GalleryVideoItem).poster_image_url || 'assets/images/hero-default.png';
    }

    return (this.gallery[0].item as GalleryImageItem).url || (this.gallery[0].item as GalleryImageItem).cropped_url || 'assets/images/hero-default.png';
  }

  close() {
    this.service.close();
  }

  isActiveIndex(index) {
    if(!this.swiper?.swiperRef) {
      return false;
    }

    return this.swiper.swiperRef.realIndex === index;
  }

  slidePrev() {
    this.swiper?.swiperRef?.slidePrev();
  }

  slideNext() {
    this.swiper?.swiperRef?.slideNext();
  }

  onSlideChange() {

  }
}
