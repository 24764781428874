<div class="create-flow-modal" *ngIf="opened">
  <div class="create-flow-modal__body">
    <div class="create-flow-modal__main">
      <div *ngIf="(loading$ | async)" class="create-flow-modal__loading-overlay"></div>
      <!-- Step 2: Profile -->
      <div class="create-flow-modal__content">
        <cto-edit-profile-screen (loading)="setLoading($event)" (success)="profileSuccess()" [createMode]="false"></cto-edit-profile-screen>
        <div class="create-flow-modal__actions">
          <div class="create-flow-modal__action-wrapper">
            <button (click)="cancel()" type="button" class="new-form-button">Cancel</button>
          </div>
          <div class="create-flow-modal__action-wrapper"></div>
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button new-form-button--primary" (click)="submitProfile()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="create-flow-modal__backdrop"></div>
</div>
