import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import videojs from '@mux/videojs-kit';

@Component({
  selector: 'cto-video-element',
  templateUrl: './video-element.component.html',
  styleUrls: ['./video-element.component.scss']
})
export class VideoElementComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  @Input() url: string = '';
  @Input() poster: string = '';

  instance: any;

  constructor() { }

  ngOnInit(): void {
  }

  private getPlaybackID(url: string) {
    return url.split('/').pop().split('.')[0];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(!this.videoElement?.nativeElement) {
        return;
      }

      this.instance = videojs(this.videoElement.nativeElement);
      this.instance.src({
        src: this.getPlaybackID(this.url),
        type: 'video/mux',
      });
    }, 100);
  }

}
