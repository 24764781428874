import { Component, OnInit } from '@angular/core';
import {ErrorAlertService} from '../../../core/services/error-alert.service';

@Component({
  selector: 'cto-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {

  constructor(
    public service: ErrorAlertService,
  ) { }

  ngOnInit(): void {
  }

  refresh() {
    window.location.reload();
  }

}
