import { Component, OnInit } from '@angular/core';
import {VideoCoverService} from '../../../core/services/video-cover.service';
import {GalleryVideoItem} from '../../../core/interfaces/gallery.interface/gallery-video-item.interface';
import {ReplaySubject} from 'rxjs';
import {FilePickerOptions} from '../../../core/interfaces/file-picker-options';
import {CroppedImageData} from '../image-cropper/image-cropper.component';
import {ImageCropperService} from '../image-cropper/image-cropper.service';
import {HeicFileConverterService} from '../../../core/services/heic-file-converter.service';

@Component({
  selector: 'cto-edit-video-cover',
  templateUrl: './edit-video-cover.component.html',
  styleUrls: ['./edit-video-cover.component.scss']
})
export class EditVideoCoverComponent implements OnInit {

  opened = false;

  item: GalleryVideoItem;

  filePickerOptions: FilePickerOptions = {
    accept: 'image/*,.heic,.heif',
  };

  subject$: ReplaySubject<any>;

  poster = {
    file: null,
    url: '',
  }

  constructor(
    private service: VideoCoverService,
    private cropper: ImageCropperService,
    private heic: HeicFileConverterService,
  ) { }

  ngOnInit(): void {
    this.service.options$.subscribe(async (data) => {
      if(!data?.item) {
        this.opened = false;
        this.item = null;
        this.subject$ = null;
        return;
      }

      this.subject$ = data.subject$;
      this.item = data.item;
      this.poster.url = data.item?.poster_image_url || '';
      this.opened = true;
    });
  }

  onFileChange(files: FileList) {
    if(!files?.length) {
      return;
    }

    const file = files[0];

    if(!file) {
      return;
    }

    void this.initImageFlow(file);
  }

  async initImageFlow(file: File) {
    const converted = await this.heic.convert(file);

    this.cropper.open({
      src: converted,
    }).subscribe((data: CroppedImageData) => {
      if(!data) {
        return;
      }

      console.log('got cover data', data);

      this.poster = {
        file: converted,
        url: data.url,
      }
    });
  }

  cancel() {
    this.subject$.next(null);
  }

  save() {
    if(!this.poster.file) {
      this.subject$.next(null);
    }

    this.subject$.next(this.poster);
  }

  deletePoster() {
    this.poster = {
      file: null,
      url: '',
    }
  }
}
