import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Cleave from 'cleave.js';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'cto-contribution-input',
  templateUrl: './contribution-input.component.html',
  styleUrls: ['./contribution-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContributionInputComponent,
    },
  ]
})
export class ContributionInputComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @ViewChild('input', { static: false }) input: ElementRef;

  cleave;

  touched = false;
  disabled = false;

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cleave = new Cleave(this.input.nativeElement, {
        numeral: true,
        prefix: '$',
        numeralPositiveOnly: true,
        numericOnly: true,
        numeralDecimalScale: 0,
        onValueChanged: (event) => {
          this.onChange(event.target.rawValue.substring(1));
        }
      });
    }, 100);
  }

  ngOnDestroy() {
    this.cleave.destroy();
    this.cleave = null;
  }

  writeValue(value: string) {
    setTimeout(() => {
      if(!this.cleave) {
        return;
      }

      this.cleave.setRawValue(value);
    }, 200);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (this.touched) {
      return;
    }

    this.onTouched();
    this.touched = true;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
