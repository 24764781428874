<video
  #videoElement
  class="video-js"
  controls
  preload="auto"
  width="100%"
  [poster]="poster"
  style="width: 100%; height: 100%;"
>
</video>
