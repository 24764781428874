import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {CroppedImageData} from './image-cropper.component';
import {first, tap} from 'rxjs/operators';

export interface ImageCropperOptions {
  src: File | string;
  silent?: boolean;
  cropData?: any;
  position?: {
    bottom: number;
    left: number;
    top: number;
    right: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {
  options$ = new BehaviorSubject<{ options: ImageCropperOptions, subject$: ReplaySubject<CroppedImageData> } | null>(null);

  constructor() {}

  open(options: ImageCropperOptions): Observable<CroppedImageData> {
    const subject$ = new ReplaySubject<CroppedImageData | null>(1);

    this.options$.next({ options, subject$ });

    return subject$.pipe(first(), tap(() => this.close()));
  }

  close() {
    this.options$.next(null);
  }
}
