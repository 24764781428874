import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {filter, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../interfaces/user';
import {PreloaderService} from './preloader.service';
import {StoreKey, StoreService} from './store.service';
import {UserProfile} from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // state = {
  //   profile: new ReplaySubject<any>(),
  //   user: new ReplaySubject<User>(),
  // };

  state = {
    profile: new BehaviorSubject<any>(null),
    user: new BehaviorSubject<User>(null),
  }

  constructor(
    private oauth: OAuthService,
    private router: Router,
    private http: HttpClient,
    private loader: PreloaderService,
    private store: StoreService,
  ) {
    // TODO: There needs to be a better way.
    this.oauth.setStorage(localStorage);
    (this.oauth as any).saveNoncesInLocalStorage = true; // This is protected!

    // Automatically load user profile
    this.oauth.events
      .pipe(
        // tap(e => console.log('[AUTH EVENT]', e)),
        filter(e => e.type === 'token_received'),
      )
      .subscribe(async () => await this.fetchUserProfile());
  }

  get user(): Observable<User> {
    return this.state.user.asObservable();
  }

  get profile() {
    return this.state.profile.asObservable();
  }

  get hasAccessToken() {
    return this.oauth.hasValidAccessToken();
  }

  get token() {
    return this.oauth.getAccessToken();
  }

  async fetchUserProfile() {
    const { info: profile } = (await this.oauth.loadUserProfile()) as { info: UserProfile; };

    // console.log('Loaded user profile:', profile);

    this.store.set<UserProfile>(StoreKey.UserProfile, profile);
  }

  configure() {
    this.loader.show();

    this.oauth.configure(environment.authConfig);
    this.oauth.loadDiscoveryDocumentAndTryLogin().then(async _ => {
      // if (useHash) {
      //   this.router.navigate(['/']);
      // }

      if(localStorage.getItem('cto:logout')) {
        await this.oauth.revokeTokenAndLogout();
        localStorage.removeItem('cto:logout');
      }

      if(this.hasAccessToken) {
        // this.login();
        await this.fetchUserProfile();

        if(localStorage.getItem('cto:dashboard-redirect')) {
          localStorage.removeItem('cto:dashboard-redirect');
          await this.router.navigate(['/dashboard']);
        }
      }

      this.loader.hide();
    });

    // Optional
    this.oauth.setupAutomaticSilentRefresh();
  }

  async login(state?: string) {
    localStorage.setItem('cto:dashboard-redirect', '1');

    // Tweak config for code flow
    this.oauth.configure(environment.authConfig);
    await this.oauth.loadDiscoveryDocument();
    // sessionStorage.setItem('flow', 'code');

    this.oauth.initLoginFlow(state);
    // the parameter here is optional. It's passed around and can be used after logging in
  }

  async signup(state?: string) {
    localStorage.setItem('cto:dashboard-redirect', '1');

    // Tweak config for code flow
    this.oauth.configure(environment.authConfig);
    await this.oauth.loadDiscoveryDocument();
    // sessionStorage.setItem('flow', 'code');

    this.oauth.initLoginFlow(state, { screen_hint: 'register' });
    // the parameter here is optional. It's passed around and can be used after logging in
  }

  async logout() {
    localStorage.removeItem('cto:dashboard-redirect');

    const profile = this.store.value<UserProfile>(StoreKey.UserProfile);

    if(!profile) {
      return;
    }

    // console.log('profile', profile);

    const url = `${environment.url.signon}/oauth2/logout?client_id=${profile.aud[0]}&access_token=${this.token}`;

    this.state.profile.next(null);
    this.state.user.next(null);

    localStorage.setItem('cto:logout', 'true');

    location.replace(url);
    await this.oauth.revokeTokenAndLogout();
  }

  changePassword() {
    const profile = this.store.value<UserProfile>(StoreKey.UserProfile);

    if(!profile) {
      return;
    }

    const url = `${environment.url.signon}/user/change_password?client_id=${profile.aud[0]}&redirect_back=${window.location.origin}`;

    location.replace(url);
  }

  me() {
    return this.http.get<User>('/api/v1/users/me')
      .pipe(tap(user => {
        // console.log('got user on me:', user);
        this.state.user.next(user);
        this.store.set<User>(StoreKey.User, user);
      }));
  }
}
