import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../../core/services/notification.service';

@Component({
  selector: 'cto-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications = [];

  constructor(
    private service: NotificationService,
  ) { }

  ngOnInit(): void {
    this.service.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });
  }

  close() {
    this.service.close();
  }

  clickOutside() {
    this.close();
  }
}
