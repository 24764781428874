import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  state = {
    opened: new BehaviorSubject<string>(''),
  }

  constructor() { }

  get opened() {
    return this.state.opened.asObservable();
  }

  private getPlaybackID(url: string) {
    return url.split('/').pop().split('.')[0];
  }

  open(url: string) {
    this.state.opened.next(this.getPlaybackID(url));
  }

  close() {
    this.state.opened.next('');
  }
}
