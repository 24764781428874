<div class="create-flow-modal" *ngIf="opened">
  <div class="create-flow-modal__body">
    <div class="create-flow-modal__main">
      <div *ngIf="(loading$ | async)" class="create-flow-modal__loading-overlay"></div>
      <div class="create-flow-modal__content">
        <div class="create-flow-modal__screen create-flow-modal__contribution-screen">
          <div class="create-flow-modal__gallery-header">
            <h2 class="create-flow-modal__gallery-title" style="text-align: center;">Set Contribution Amounts</h2>
          </div>
          <div class="create-flow-modal__contributions">
            <div class="create-flow-modal__contribution-text">
              Adjust the default contribution amounts to best suit your audience and your goals.
            </div>
            <div class="create-flow-modal__contribution-inputs">
              <div *ngFor="let input of contributions.controls">
                <cto-contribution-input [formControl]="atf(input)"></cto-contribution-input>
              </div>
            </div>
          </div>
        </div>
        <div class="create-flow-modal__actions">
          <div class="create-flow-modal__action-wrapper">
            <button type="button" class="new-form-button" (click)="cancel()">Cancel</button>
          </div>
          <div class="create-flow-modal__action-wrapper"></div>
          <div class="create-flow-modal__action-wrapper">
            <button [disabled]="contributions.invalid" type="button" class="new-form-button new-form-button--primary" (click)="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="create-flow-modal__backdrop"></div>
</div>
