import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FilePickerOptions } from '../../../core/interfaces/file-picker-options';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { EditCardModalService } from '../../../core/services/edit-card-modal.service';
import { CardService } from '../../../core/services/card.service';
import { CardType } from '../../../core/enums/card-type';
import { FileService } from '../../../core/services/file.service';
import { ImageType } from '../../../core/enums/image-type';
import { PreloaderService } from '../../../core/services/preloader.service';
import { GlobalService } from '../../../core/services/global.service';
import CustomValidators from '../../../core/global/custom-validators';
import StringUtils from '../../../core/global/string-utils';
import { CropperService } from '../../../core/services/cropper.service';
import { LinkType } from '../../../core/enums/link-type';
import { StoreService } from '../../../core/services/store.service';
import { Card, CardResponse } from '../../../core/interfaces/card.interface';
import { ImageUploadData } from '../../../core/interfaces/image.interface';
import { PollService } from '../../../core/services/poll.service';
import { SocialMedia, SocialRegexPattern } from '../../../core/enums/social-media';
import { DOCUMENT } from '@angular/common';
import { HeicFileConverterService } from '../../../core/services/heic-file-converter.service';
import heic2any from 'heic2any';

@Component({
  selector: 'cto-card-edit-modal',
  templateUrl: './card-edit-modal.component.html',
  styleUrls: ['./card-edit-modal.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(50%)' }),
        animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, transform: 'translateX(-50%)' }))
      ])
    ]),
  ],
})
export class CardEditModalComponent implements OnInit, OnDestroy {
  cover: any;
  coverFile: any;
  socialMedia = SocialMedia;

  avatar: any;
  avatarFile: any;

  openedSub: Subscription;

  filePickerOptions: FilePickerOptions = {
    accept: 'image/*, .heic, .heif',
  };

  state = {
    cardId: '',
    modal: {
      opened: false,
      screen: 1,
    }
  };

  errors = {
    facebook: {
      status: false,
      message: '',
    },
    twitter: {
      status: false,
      message: ''
    },
    instagram: {
      status: false,
      message: ''
    },
    linkedin: {
      status: false,
      message: '',
    },
    tiktok: {
      status: false,
      message: ''
    },
  }

  forms = {
    slug: this.fb.group({
      name: ['', Validators.required],
    }),
    card: this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(64)]],
      summary: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(1000)]],
      contribution_amounts: this.fb.array([
        this.fb.group({ price: [0, [Validators.required, CustomValidators.price(2, 10000)]] }),
        this.fb.group({ price: [0, [Validators.required, CustomValidators.price(2, 10000)]] }),
        this.fb.group({ price: [0, [Validators.required, CustomValidators.price(2, 10000)]] }),
      ]),
      social_twitter_url: ['', [CustomValidators.url()]],
      social_facebook_url: ['', [CustomValidators.url()]],
      social_instagram_url: ['', [CustomValidators.url()]],
      social_website_url: ['', [CustomValidators.url()]],
      social_tiktok_url: ['', [CustomValidators.url()]],
      social_linkedin_url: ['', [CustomValidators.url()]],
    }),
  };

  form = {
    social_facebook_username: '',
    social_instagram_username: '',
    social_twitter_username: '',
    social_tiktok_username: '',
    social_linkedin_username: ''
  }

  constructor(
    private fb: FormBuilder,
    private service: EditCardModalService,
    private cards: CardService,
    private file: FileService,
    private loader: PreloaderService,
    public global: GlobalService,
    private cropper: CropperService,
    private store: StoreService,
    private poll: PollService,
    private renderer: Renderer2,
    private fileConvert: HeicFileConverterService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.openedSub = this.service.opened.subscribe(opened => {
      this.state.modal.opened = opened;
      this.renderer.addClass(this.document.body, 'modal-open');

      if(!opened) {
        this.renderer.removeClass(this.document.body, 'modal-open');
        this.forms.card.reset();
        this.avatar = '';
        return;
      }

      this.cards.getCard().pipe(first()).subscribe(({ card }: CardResponse) => {
        this.state.cardId = card.id;

        this.forms.card.patchValue({
          ...card,
          social_facebook_url: card.social_facebook_url,
          social_twitter_url: card.social_twitter_url,
          social_instagram_url: card.social_instagram_url,
          social_tiktok_url: card.social_tiktok_url,
          social_linkedin_url: card.social_linkedin_url,
          contribution_amounts: this.updateContributionAmounts(card),
        });

        this.avatar = card.avatar_image_url || '';
        this.cover = card.cover_image_url || '';

        this.form.social_facebook_username = card.social_facebook_url ? this.stripSocialMediaLink(card.social_facebook_url) : '';
        this.form.social_instagram_username = card.social_instagram_url ? this.stripSocialMediaLink(card.social_instagram_url) : '';
        this.form.social_twitter_username = card.social_twitter_url ? this.stripSocialMediaLink(card.social_twitter_url) : '';
        this.form.social_tiktok_username = card.social_tiktok_url ? this.stripSocialMediaLink(card.social_tiktok_url) : '';
        this.form.social_linkedin_username = card.social_linkedin_url ? this.stripSocialMediaLink(card.social_linkedin_url) : '';
      });

      // this.cards.card.pipe(first()).subscribe(card => {
      //   console.log('edit card', card);
      //   this.state.cardId = card._id;
      //
      //   // const links = this.updateLinks(card);
      //
      //   this.forms.card.patchValue({ ...card });
      //   // this.updateLinks();
      //
      //   if(card.ctologoImage?.publicUrl) {
      //     this.avatar = card.ctologoImage.publicUrl;
      //   }
      //
      //   if(card.buttoncardcoverImage?.publicUrl) {
      //     this.cover = card.buttoncardcoverImage.publicUrl;
      //   }
      // });

      this.cards.getCards().pipe(first()).subscribe();
    });
  }

  updateContributionAmounts(card: Card) {
    if(!card.contribution_amounts?.length) {
      return [
        { price: 5 },
        { price: 50 },
        { price: 100 },
      ]
    }

    return card.contribution_amounts.map(price => ({ price }));
  }

  stripSocialMediaLink(link: string) {
    if (!link) return
    return link.substring(link.lastIndexOf('/') + 1);
  }

  isLinkValid(index: string) {
    return this.forms.card.get('social_' + index + '_url').valid;
  }

  checkIfSocialMedia(index: string, value: string) {
    const re = new RegExp(`^((?:http|https):\\/\\/)?((w{3}\\.)?)${index}.com\\/.*`, 'i')
    return re.test(value);
  }

  onPasteChange(index: string, e: ClipboardEvent) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window['clipboardData'];
    const text = clipboardData.getData('text');
    this.setSocialMediaValues(index, text);
  }

  removeLastDirectoryPartOf(value) {
    let url = value.split('/');
    url.pop();
    return url.join('/');
  }

  onInputChange(index: string, value: string) {
    this.setSocialMediaValues(index, value);
  }

  setSocialMediaValues(index: string, value: string) {
    if (this.checkIfSocialMedia(index, value)) {
      let url = value;
      if (url.endsWith('/')) {
        url = this.removeLastDirectoryPartOf(url);
      }
      let username = this.stripSocialMediaLink(url);
      this.form['social_' + index + '_username'] = username;
      this.forms.card.controls['social_' + index + '_url'].setValue(`${SocialMedia[index]}${username}`);
      this.errors[index].status = false;
    } else {
      if (value === '') {
        this.errors[index].status = false;
        this.form['social_' + index + '_username'] = '';
        this.forms.card.controls['social_' + index + '_url'].setValue(null);
      } else {
        const re = new RegExp(SocialRegexPattern[index], 'i');
        if (re.test(value)) {
          this.errors[index].status = false;
          this.form['social_' + index + '_username'] = value;
          this.forms.card.controls['social_' + index + '_url'].setValue(`${SocialMedia[index]}${value}`);
        } else {
          this.errors[index].status = true;
          this.errors[index].message = 'Invalid username.';
        }
      }
    }
  }

  async onFileChange(files: FileList) {
    if (!files?.length) {
      return;
    }

    // const file = files[0];

    // this.cover = await this.file.readAsDataURL(file);
    // this.coverFile = file;

    this.fileConvert.convert(files[0]).then((value) => {
      this.cropper.init({
        type: 'avatar',
        file: value,
      }).then(async file => {
        this.avatar = await this.file.readAsDataURL(file);
        this.avatarFile = file;
      });
    });
  }

  onCoverChange(files: FileList) {
    if (!files?.length) {
      return;
    }

    // const file = files[0];

    // this.cover = await this.file.readAsDataURL(file);
    // this.coverFile = file;

    this.fileConvert.convert(files[0]).then((value) => {
      this.cropper.init({
        type: 'cover',
        file: value,
      }).then(async file => {
        this.cover = await this.file.readAsDataURL(file);
        this.coverFile = file;
      });
    });
  }

  get nameCharacters() {
    return 64 - this.forms.card.get('title').value?.length || 0;
  }

  get descCharacters() {
    return 1000 - this.forms.card.get('summary').value?.length || 0;
  }

  async cardSubmit() {
    if(!this.forms.card.valid) {
      return;
    }

    const data = { ...this.forms.card.value };

    this.loader.show();

    data.contribution_amounts = data.contribution_amounts.map(item => StringUtils.priceStringToNumber(item.price, true));

    const [ avatarUrl, coverUrl ] = await Promise.all([
      this.uploadImage(this.avatarFile),
      this.uploadImage(this.coverFile),
    ]);

    if(avatarUrl) {
      data.avatar_image_url = avatarUrl;
    }

    if(coverUrl) {
      data.cover_image_url = coverUrl;
    }

    this.cards.updateCardV2(data).pipe(first()).subscribe(async res => {
      console.log('card updated', res);

      this.service.saved(true);
      this.loader.hide();
      this.service.close();

      // this.cards.pollImages(this.state.cardId);
    });

    // this.cards.updateCard(this.state.cardId, data, CardType.Button)
    //   .pipe(first()).subscribe(async (res) => {
    //     console.log('card updated', res);
    //
    //     await this.uploadAvatar();
    //     await this.uploadCover();
    //
    //     this.service.saved(true);
    //     this.loader.hide();
    //     this.service.close();
    //
    //     this.cards.pollImages(this.state.cardId);
    //
    //     // window.location.reload();
    // });
  }

  formatLinks(links: { linkType?: LinkType; url?: string; }[]) {
    if(!links?.length) {
      return [];
    }

    return links.map(link => {
      let url = link.url.trim();

      if(!/^https?:\/\//.test(url)) {
        url = 'https://' + url;
      }

      return { ...link, url };
    });
  }

  uploadImage(file: File) {
    return new Promise(resolve => {
      if(!file) {
        resolve('');
        return;
      }

      this.file.getUploadUrl().pipe(first()).subscribe(({ upload_url }: ImageUploadData) => {
        this.file.upload(file, upload_url).pipe(first()).subscribe(uploaded => {
          resolve(uploaded?.result?.variants?.[0] || '');
        }, () => resolve(''));
      }, () => resolve(''));
    });
  }

  uploadAvatar() {
    return new Promise(resolve => {
      if(!this.avatarFile) {
        resolve(true);
        return;
      }

      this.file.getUploadUrl().pipe(first()).subscribe(({ upload_url }: any) => {
        this.file.upload(this.avatarFile, upload_url).subscribe(uploadRes => {
          console.log('uploaded avatar', uploadRes);
          resolve(uploadRes?.result?.variants?.[0]);
        });
      });
    });
  }

  uploadCover() {
    return new Promise(resolve => {
      if(!this.coverFile) {
        resolve(true);
        return;
      }

      this.file.getUploadUrls({
        cardId: this.state.cardId,
        cardType: CardType.Button,
        imageType: ImageType.Cover,
        multiple: 1,
      }).pipe(first()).subscribe(({ uploadUrl }: any) => {
        this.file.upload(this.coverFile, uploadUrl).subscribe(uploadRes => {
          console.log('uploaded cover', uploadRes);
          // this.cards.pollImages(this.state.cardId);
          resolve(true);
        });
      });
    });
  }

  close() {
    if(window.confirm('Are you sure you want to cancel? Any unsaved changes will be lost.')) {
      this.service.close();
      this.forms.card.reset();
      this.avatar = '';
      this.cover = '';
      this.errors['facebook'].status = false;
      this.errors['instagram'].status = false;
      this.errors['twitter'].status = false;
      this.errors['tiktok'].status = false;
      this.errors['linkedin'].status = false;
    }
  }

  ngOnDestroy() {
    this.openedSub?.unsubscribe();
    this.renderer.removeClass(this.document.body, 'modal-open');
  }
}
