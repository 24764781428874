import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UnloadService} from '../../../core/services/unload.service';

@Injectable({
  providedIn: 'root'
})
export class EditGalleryService {
  state = {
    opened: new BehaviorSubject<boolean>(false),
  };

  constructor(
    private unload: UnloadService,
  ) { }

  get opened() {
    return this.state.opened.asObservable();
  }

  toggle(opened: boolean) {
    this.state.opened.next(opened);
  }

  open() {
    this.toggle(true);
    this.unload.bind('Are you sure you want to leave? Any unsaved changes will be lost.');
  }

  close() {
    this.toggle(false);
    this.unload.unbind()
  }
}
