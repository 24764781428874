import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notifications$ = new BehaviorSubject<string[]>([]);

  constructor() {
  }

  init(notifications: string[]) {
    this.notifications$.next(notifications);
  }

  append(notifications: string[]) {
    this.notifications$.next([...this.notifications$.value, ...notifications]);
  }

  close() {
    this.notifications$.next([]);
  }
}
