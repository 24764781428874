import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {VideoPlayerService} from '../../../core/services/video-player.service';
import videojs from '@mux/videojs-kit';

@Component({
  selector: 'cto-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  state = {
    shown: false,
    playbackId: '',
  }

  instance: any;

  constructor(
    private player: VideoPlayerService,
  ) { }

  ngOnInit(): void {
    this.player.opened.subscribe(playbackId => {
      this.state.shown = !!playbackId;
      this.state.playbackId = playbackId;

      setTimeout(() => {
        this.videoElement?.nativeElement.addEventListener('webkitendfullscreen', () => {
          this.close();
        });

        if(this.state.shown && this.videoElement?.nativeElement) {
          this.instance = videojs(this.videoElement.nativeElement);
          this.instance.src({
            src: this.state.playbackId,
            type: 'video/mux',
          });
        } else {
          this.instance = null;
        }
      }, 100);
    });
  }

  close() {
    this.player.close();
    this.instance.pause();
  }
}
