import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CardSchema} from '../../../core/schemas/card.schema';
import {FormControl, Validators} from '@angular/forms';
import {Card, SocialItem} from '../../../core/interfaces/card.interface';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {SlugService} from '../../../core/services/slug.service';
import {HeicFileConverterService} from '../../../core/services/heic-file-converter.service';
import {FileService} from '../../../core/services/file.service';
import {CardService} from '../../../core/services/card.service';
import {RefreshService} from '../../../core/services/refresh.service';
import {GlobalService} from '../../../core/services/global.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'cto-edit-profile-screen',
  templateUrl: './edit-profile-screen.component.html',
  styleUrls: ['./edit-profile-screen.component.scss']
})
export class EditProfileScreenComponent implements OnInit {
  @Output() loading = new EventEmitter<boolean>();
  @Output() success = new EventEmitter<boolean>();

  @Input() createMode: boolean = false;

  schema = CardSchema;

  urlRegex = /^(?![.,])(?:(http)s?:\/\/)?(?:www\.)?.+\..*/i;

  websiteControl = new FormControl('', [Validators.pattern(this.urlRegex)]);

  contributionAmounts = [5, 10, 20];

  social: SocialItem[] = [
    {
      name: 'social_facebook_url',
      prefix: 'https://facebook.com/',
      control: new FormControl(''),
      regex: /(?:https?:\/\/www\.|(?!www))facebook\.com\/([^\/]+)\/?/i,
    },
    {
      name: 'social_instagram_url',
      prefix: 'https://instagram.com/',
      control: new FormControl(''),
      regex: /(?:https?:\/\/www\.|(?!www))instagram\.com\/([^\/]+)\/?/i,
    },
    {
      name: 'social_twitter_url',
      prefix: 'https://twitter.com/',
      control: new FormControl(''),
      regex: /(?:https?:\/\/www\.|(?!www))twitter\.com\/([^\/]+)\/?/i,
    },
    {
      name: 'social_tiktok_url',
      prefix: 'https://tiktok.com/@',
      control: new FormControl(''),
      regex: /(?:https?:\/\/www\.|(?!www))tiktok\.com\/@?([^\/]+)\/?/i,
      formatter: (value: string) => {
        return value.replace(/^@*/, '');
      },
    },
    {
      name: 'social_linkedin_url',
      prefix: 'https://linkedin.com/in/',
      control: new FormControl(''),
      regex: /(?:https?:\/\/www\.|(?!www))linkedin\.com\/in\/([^\/]+)\/?/i,
    },
  ];

  avatar: { file$: BehaviorSubject<File | null>; url$: BehaviorSubject<any> } = {
    file$: new BehaviorSubject<File | null>(null),
    url$: new BehaviorSubject<any>(''),
  }

  titleControl = new FormControl('', [Validators.required, Validators.minLength(CardSchema.title.minLength), Validators.maxLength(CardSchema.title.maxLength)]);
  summaryControl = new FormControl('', [Validators.required, Validators.minLength(CardSchema.summary.minLength), Validators.maxLength(CardSchema.summary.maxLength)]);

  constructor(
    private store: StoreService,
    private slug: SlugService,
    private heic: HeicFileConverterService,
    private file: FileService,
    private cards: CardService,
    private refresh: RefreshService,
    public global: GlobalService,
  ) { }


  ngOnInit(): void {
    if(this.createMode) {
      this.loading.emit(false);
      return;
    }

    this.store.get<Card>(StoreKey.Card).pipe(first()).subscribe(card => {
      this.titleControl.patchValue(card.title);
      this.summaryControl.patchValue(card.summary);

      this.patchSocial(card);
      this.avatar.url$.next(card.avatar_image_url || '');
      this.loading.emit(false);
    });
  }

  get nameCharacters() {
    return CardSchema.title.maxLength - this.titleControl.value?.length || 0;
  }

  get summaryCharacters() {
    return CardSchema.summary.maxLength - this.summaryControl.value?.length || 0;
  }

  get isFormValid() {
    return this.titleControl.valid && this.summaryControl.valid && this.websiteControl.valid && this.isSocialValid();
  }

  isSocialValid() {
    return !this.social.some(item => !item.control.valid);
  }

  patchSocial(card: Card) {
    for(let item of this.social) {
      if(!card[item.name]) {
        continue;
      }

      let username = card[item.name].split('/').at(-1);
      username = username.replace(/^@*/, '');

      item.control.patchValue(username);
    }

    this.websiteControl.patchValue(card.social_website_url || '');
  }

  async submitProfile() {
    this.loading.emit(true);

    const socialObject = this.social.reduce<{ [key: string]: string }>((acc, curr) => {
      acc[curr.name] = curr.control.value ? `${curr.prefix}${curr.control.value.replace(/^@*/, '')}` : '';

      return acc;
    }, {});

    let social_website_url = this.websiteControl.value;

    const matchUrl = social_website_url.match(this.urlRegex);

    if(matchUrl?.length && matchUrl[1] !== 'http') {
      social_website_url = `https://${social_website_url}`;
    }

    const card: Partial<Card> = {
      title: this.titleControl.value,
      summary: this.summaryControl.value,
      social_website_url,
      ...(this.createMode ? {
        contribution_amounts: this.contributionAmounts,
      }: {}),
      ...socialObject,
    }

    if(this.avatar.file$.value) {
      const upload_data = await this.file.uploadImage(this.avatar.file$.value);

      card.avatar_image_url = upload_data.url;
    }

    this.cards.updateCardV2(card).pipe(first()).subscribe(response => {
      console.log('Created card:', response);

      setTimeout(() => {
        this.refresh.refresh();
        this.loading.emit(false);
        this.success.emit(true);
      }, 1000);
    });
  }

  onSocialPaste(e, item: SocialItem) {
    e.preventDefault();
    e.stopPropagation();

    const paste: string = (e.clipboardData || (window as any).clipboardData)?.getData?.('text');

    if(!paste) {
      return;
    }

    const username = this.getUsernameFromPaste(paste, item.regex);

    if(!username) {
      return;
    }

    item.control.patchValue(item.formatter?.(username) || username);
  }

  getUsernameFromPaste(paste: string, regex: RegExp) {
    let match = paste.match(regex);

    if(match?.length > 1) {
      return match[1] || '';
    }

    match = paste.match(this.urlRegex);

    if(!match?.length) {
      return paste;
    }

    return '';
  }

  async onAvatarFileChange(files: FileList) {
    if(!files.length) {
      return;
    }

    const file = files[0];

    if(!file) {
      return;
    }

    const converted = await this.heic.convert(file);

    this.avatar.file$.next(converted);
    this.avatar.url$.next(await this.file.readAsDataURL(converted));
  }
}
