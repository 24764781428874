<div class="create-flow-modal__screen create-flow-modal__profile-screen">
  <div class="create-flow-modal__profile-header">
    <h2 class="create-flow-modal__profile-title" style="text-align: center;">Create Your Profile</h2>
    <div ctoFilePicker class="create-flow-modal__avatar-picker" (change)="onAvatarFileChange($event)" [options]="{ accept: 'image/*, .heic, .heif' }">
      <img [src]="(avatar.url$ | async) || '/assets/images/avatar-default.svg'" alt="User avatar" class="create-flow-modal__avatar">
      <div class="create-flow-modal__avatar-button">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 8.5H6.5V13.5C6.5 14.0521 6.94786 14.5 7.5 14.5C8.05214 14.5 8.5 14.0521 8.5 13.5V8.5H13.5C14.0521 8.5 14.5 8.05214 14.5 7.5C14.5 6.94786 14.0521 6.5 13.5 6.5H8.5V1.5C8.5 0.947858 8.05214 0.5 7.5 0.5C6.94786 0.5 6.5 0.947858 6.5 1.5V6.5H1.5C0.947858 6.5 0.5 6.94786 0.5 7.5C0.5 8.05214 0.947858 8.5 1.5 8.5Z" fill="#602576" stroke="#602576"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="create-flow-modal__profile-content">
    <div class="new-form-input-group">
      <div class="new-form-input">
        <input [formControl]="titleControl" type="text" class="new-form-input__field" placeholder="Name" [maxlength]="schema.title.maxLength">
      </div>
      <span *ngIf="!global.controlValid(titleControl)" class="form-message form-message--error">Name needs to be between {{ schema.title.minLength }} and {{ schema.title.maxLength }} characters.</span>
      <div class="form-counter">{{ nameCharacters }} characters left.</div>
    </div>
    <div class="new-form-input-group">
      <div class="new-form-input">
        <textarea [formControl]="summaryControl" class="new-form-input__textarea" placeholder="Tell people what their contribution will help you achieve." rows="4" [maxlength]="schema.summary.maxLength"></textarea>
      </div>
      <span *ngIf="!global.controlValid(summaryControl)" class="form-message form-message--error">Summary needs to be between {{ schema.summary.minLength }} and {{ schema.summary.maxLength }} characters.</span>
      <div class="form-counter">{{ summaryCharacters }} characters left.</div>
    </div>
    <h2 class="create-flow-modal__profile-title" style="margin-top: 30px; margin-bottom: 10px;">Social Media</h2>
    <div class="new-form-input-group">
      <div class="new-form-input">
        <input [formControl]="websiteControl" type="text" class="new-form-input__field" placeholder="https://website.com/">
      </div>
    </div>
    <div *ngFor="let item of social" class="new-form-input-group">
      <div class="new-form-input">
        <span class="new-form-input__prefix new-form-input__prefix--placeholder">{{ item.prefix }}</span>
        <input type="text" class="new-form-input__field" [placeholder]="item.placeholder || ''" [formControl]="item.control" (paste)="onSocialPaste($event, item)">
      </div>
    </div>
  </div>
</div>
